.trust__note {
  position: relative;
  color: #fff;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  padding: 8px 5%;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  .trust_note_progress_bar {
    align-self: self-start;
    width: 75%;
    height: 9px;
    z-index: 1;
    background: #b8e9c8;
    border-radius: 10px;
  }

  &::after {
    display: inline-block;
    position: absolute;
    height: 8px;
    width: 90%;
    inset: auto 0 8px;
    margin-inline: auto;
    content: "";
    background: #fdcaca;
    border-radius: 10px;
  }
}
