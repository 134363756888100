@import "../../general__style/var";

.text_badge {
    color: $text-white;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
    height: 28px;
    min-width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 12px;
}

.text_badge.pink {
    color: $active-buttons-bg-pink;
    background-color: $dark-pink
}

.text_badge.green {
    color: $active-buttons-bg-dark;
    background-color: $dark-green
}


.text_badge_light_gray {
    color: $text-white;
    background-color: $light-gray;
    font-size: 14px;
    border-radius: 10px;
    height: 28px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text_badge_cyan {
    font-size: 10px;
    font-weight: 600;
    color: $active-buttons-bg;
    background-color: $light-gray;
    min-width: 50px;
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 540px) {
        font-size: 8px;
    }
}

.custom_badge_odd {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 12px;
    background-color: $dark-green;
    border: 1.5px solid $active-buttons-bg-dark;
    box-shadow: 0px 0px 4px 2px $active-buttons-bg-dark inset;
    border-radius: 4px;
    column-gap: 6px;
    color: $text-white;
}

.custom_badge_odd.large {
    padding: 4px 12px;
}

.custom_badge_odd.outline_pink {
    background-color: $dark-pink;
    border: 1.5px solid $active-buttons-bg-pink;
    box-shadow: 0px 0px 4px 2px $active-buttons-bg-pink inset;
}

.player_count_badge {
    font-size: 12px;
    font-weight: 600;
    color: $active-buttons-bg;
    background-color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 24px;

    svg {
        width: 20px;
        height: 20px;
    }
}

.makao_money_badge {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 24px;

    svg {
        width: 16px;
        height: 16px;

        path {
            fill: currentColor;
        }
    }
}

.makao_money_badge.green {
    color: $active-buttons-bg-dark;
    background-color: $dark-green;
}

.makao_money_badge.pink {
    color: $active-buttons-bg-pink;
    background-color: $dark-pink;
}

.makao_money_badge.large {
    max-height: 28px;
    padding: 4px 16px;
}

.organizer_fee_badge {
    font-size: 12px;
    font-weight: 600;
    color: $active-buttons-bg-dark;
    background-color: $dark-green;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 24px;

    svg {
        width: 20px;
        height: 20px;

        path {
            stroke: $active-buttons-bg-dark;
            stroke-width: 1px;
        }
    }
}



.three_dot_badge {
    background-color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
    height: 24px;

    svg {
        width: 16px;
        height: 24px;
    }
}

.minutes60_countdown_badge {
    font-size: 12px;
    font-weight: 600;
    color: $active-buttons-bg-pink;
    background-color: $dark-pink;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 24px;
    min-width: 52px;
}

.trust_note_badge {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 28px;
}

.trust_note_badge.small {
    max-height: 24px;
    padding: 2px 12px;
}

.trust_note_badge.trusted {
    background-color: $dark-green;
    color: $active-buttons-bg-dark;

    svg {
        max-width: 24px;
        max-height: 24px;

        path {
            fill: $active-buttons-bg-dark;
        }
    }
}

.trust_note_badge.not_trusted {
    background-color: $dark-pink;
    color: $active-buttons-bg-pink;

    svg {
        max-width: 16px;
        max-height: 16px;

        path {
            fill: $active-buttons-bg-pink;
        }
    }
}