@import "../../../general__style/var";

.sidebar__content {
  background-color: $bg-color;
  height: 91vh;
  overflow-y: auto;
  position: fixed;
  z-index: 10000;
  transition: 0.5s ease-in-out;

  @media screen and (max-width: 600px) {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    left: 0;
    right: 0;
  }

  ul {
    padding: 7px 7px 50px 20px;

    a {
      text-decoration: none;
      background-color: $bg-color;
      display: flex;
      padding: 7px 22px;
      align-items: center;
      margin: 1.13rem 0;
      color: white;
      text-decoration: none;

      li {
        display: flex;
        width: 100%;
        background-color: $bg-color;

        align-items: center;
        column-gap: 10px;

        color: white;

        .default__color {
          color: white;
          display: flex;
          text-decoration: none !important;
        }
      }

      @media screen and (max-width: 540px) {
        margin-right: 0.7rem;
        background-color: $input-bg;
        border-radius: 10px;

        li {
          background-color: $input-bg;
          color: $text-white;
        }
      }
    }

    .active {
      box-shadow: 0px 0px 4px 0px $active-buttons-bg;
      border-radius: 4px;
    }

    // .active {
    //   background-color: $active-buttons-bg;
    //   border-radius: 20px;
    //   color: $active-text-color;

    //   li {
    //     align-items: center;
    //     background-color: $active-buttons-bg;
    //     color: $active-text-color;
    //   }


    //   @media screen and (max-width: 540px) {
    //     border-radius: 10px;
    //     background-color: $input-bg;
    //     color: $text-white;

    //     li {
    //       background-color: $input-bg;
    //       color: $text-white;
    //     }
    //   }
    // }

    .switch_container {
      color: $text-white;
      display: grid;
      row-gap: 1.13rem;
      column-gap: .8rem;
      margin-top: 1.13rem;
      text-align: center;

      @media screen and (max-width: 540px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        justify-content: center;
        margin-right: .6rem;

        .button_container {
          background-color: $input-bg;
          padding: .5rem .0rem;
          border-radius: 10px;
        }

        .span_2 {
          grid-column: span 2;
        }
      }
    }

    .logout {
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      height: 55px;
      cursor: pointer;
    }
  }
}

.display__none {
  position: absolute;
  left: -100%;
}