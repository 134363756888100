@import '../../general__style/var.scss';

.biggest_predictions_container_all {
    margin: 2rem 1rem;
    @media screen and (max-width: 540px) {
        margin: 1rem .8rem;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
        color: $text-white;
    }

    .loadmore__container {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
      }
}