@import "../../../general__style/var";

.dash__content {
  background-color: $bg-color;
  color: $text-white;
  padding: 20px;

  .dash__content__top {

    .dash__content__top__boxes {
      display: flex;
      overflow-x: auto;

      .images {
        position: relative;

        .card_top_images {
          position: absolute;
          left: 30px;
          top: 8px;
        }

        .text {
          position: absolute;
          top: 105px;
          left: 5rem;
        }

        .text_poker_game {
          position: absolute;
          top: 105px;
          left: 7rem;

          p {
            -webkit-text-stroke: 1px black !important;
          }
        }
      }
    }

    .dash__content__top__boxes::-webkit-scrollbar {
      display: none;
    }
  }

  .categories {
    .categories__top__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;

      h2 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .categories_grid {
      display: flex;
      column-gap: 1rem;
      overflow: scroll;
      margin-bottom: 2rem;

      .category_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        cursor: pointer;

        span {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #F4F3FC;
        }

        .cat_image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          border-radius: 16px;
          border: 2px solid #272D36;
          box-shadow: 0px 0px 3px 1px #272D36;

          .img {
            width: 24px;
            height: 24px;
            object-fit: cover;
            aspect-ratio: 1/1;
          }
        }
      }
    }

    .categories_grid::-webkit-scrollbar {
      display: none;
    }
  }

  .leaderboards {
    margin-top: 1.5rem;
    padding-bottom: 1rem;

    .leaderboards__head__top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .leaderboards__content {
      .leaderboards__tabs {
        background-color: $input-bg;
        width: fit-content;
        padding: 6px 7px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin: auto;

        .box {
          display: flex;
          column-gap: 1.5rem;

          a {
            text-decoration: none;
            color: $text-white;
            padding: 3px 5px;
          }

          a.active {
            background-color: $active-buttons-bg;
            border-radius: 6px;
            color: black;
            font-weight: bold;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 600px) {
          padding: 6px 27px;
        }
      }

      .main__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        @media screen and (max-width: 868px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 540px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 4px;
        }

        .box__down {
          display: flex;
          align-items: center;
          background-color: $dark-gray;
          padding: 2px 12px;
          border-radius: 10px;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 20px;

            p {
              color: $text-white;
              font-size: 16px;
              font-weight: 500;

            }

            .leader {
              font-weight: 600;
              font-size: 20px;
              color: $text-white;
              position: relative;

              .leader_place {
                font-size: 10px;
                position: absolute;
                top: 2px;
                right: -12px;
              }
            }

            span {
              display: flex;
              align-items: center;
              column-gap: 10px;

              img {
                width: 36px;
                object-fit: cover;
                border-radius: 50%;
                aspect-ratio: 1;
              }
            }
          }

          .right {
            font-size: 16px;
            font-weight: 500;
            color: $active-buttons-bg-dark;
            display: flex;
            gap: 4px;
          }
        }
      }
    }
  }

  .biggest_predictions_container_dashboard {
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-height: 150px;
  }
}

.tenis__images {
  width: 60%;
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 95%;
  }

  .heading__tenis {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
}