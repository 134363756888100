@import "../../../general__style/var";


.button {
  background-color: $bg-color;
  padding: 2px 8px;
  border: none;
  color: white;
  border-radius: 6px;
  font-size: .87rem;
}

.font_13px {
  font-size: 13px;
}

.timeline__tabs {
  margin-bottom: 1rem;
  gap: 8px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;

  .tab_button {
    background-color: $input-bg;
    padding: 2px 8px;
    border-radius: 8px;
    cursor: pointer;
  }

  .tab_button_nobg {
    padding: 2px 8px;
    cursor: pointer;
  }
}

.time_picker_header_custom {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  color: white;
}


.picker_modal {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 16px 8px 8px 8px;
  justify-content: center;
}

.date_time_modal {
  width: 135px;
  height: 32px;
  background-color: #121316;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 4px;
}

.time_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 5px;

  div {
    color: white;
    font-size: 10px;
    font-weight: 500;
    align-self: center;
    padding-left: 4px;
    padding-right: 3px;
    cursor: pointer
  }
}

.react-ios-time-picker-btn {
  color: $active-buttons-bg !important;
}


.react-ios-time-picker-btn.react-ios-time-picker-btn-cancel {
  color: $active-buttons-bg-pink !important;
}