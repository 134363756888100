@import '../../../general__style/var';

.send_token_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 8px;
    margin: 12px 0rem;

    .select_container_search {
        position: relative;
        width: 100%;
        margin: auto;

        .ant-select-selection-item {
            text-align: left !important;
        }

        .ant-select-open .ant-select-selection-item {
            color: $text-white;
            opacity: .5 !important;
        }

        .ant-select-single {
            height: unset !important;
        }

        .ant-select-selector {
            padding: 5px 30px 5px 40px;
            padding-right: 30px;
            padding-left: 40px;
            background-color: $dark-gray !important;
            outline: none !important;
            border: none !important;
            color: $text-white !important;

            .ant-select-selection-search {
                left: 40px;
                right: 30px;
            }

            .ant-select-selection-placeholder {
                text-align: left;
                color: white !important;
                opacity: 50% !important;
            }

            .ant-select-selection-overflow {
                row-gap: 8px;

                .ant-select-selection-overflow-item {
                    background-color: transparent;

                    .ant-select-selection-item {
                        background-color: $bg-color !important;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .ant-select-selection-item-remove {
                color: white !important;
            }
        }

        .custom-suffix {
            position: absolute;
            top: 8px;
            left: 12px;

            @media screen and (max-width: 540px) {
                left: 13px;
            }
        }

        .custom-suffix-img {
            width: 24px;
            height: 24px;
            object-fit: cover;
            border-radius: 100%;
            display: block;
        }
    }

    .input_and_select_container {
        display: flex;
        background-color: $dark-gray;
        padding: 4px 16px;
        padding-right: 0px;
        border-radius: 4px;
        max-width: 100%;

        input {
            display: flex;
            max-width: 70%;
            color: $text-white;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
            outline: none;
            border: none;
            appearance: none;
        }

        .select-container {
            position: relative;
            margin: auto;
            max-width: 30%;
            width: 29%;

            .ant-select-selector {
                width: 100%;
                padding: 6px 4px;
                background-color: $dark-gray !important;
                outline: none !important;
                border: none !important;
                color: $text-white !important;

                .ant-select-selection-placeholder {
                    color: white !important;
                    opacity: 50% !important;
                }

                .ant-select-selection-item {
                    color: $text-white !important;
                }

                .ant-select-selection-overflow {
                    row-gap: 8px;

                    .ant-select-selection-overflow-item {
                        background-color: transparent;

                        .ant-select-selection-item {
                            color: $text-white !important;
                            background-color: transparent !important;
                            font-size: 14px;
                            font-weight: 800;
                        }
                    }
                }

            }

            .custom-suffix {
                position: absolute;
                top: 7px;
                right: 4px;
            }
        }
    }

    .info_text {
        color: $text-white;
        font-size: 10px;
        opacity: 80%;
        text-align: right;
        margin-top: 2px;
    }

    .receiver_container {
        text-align: left;
        color: $text-white;

        span {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 500;
        }

        .receiver {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
            background-color: $dark-gray;
            border-radius: 4px;
            padding: 4px 10px;

            .username_img {
                display: flex;
                align-items: center;
                gap: 4px;

                .user_img {
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }

            .receiver_address {
                word-wrap: break-word;
                white-space: pre-wrap;
                text-align: left;
                font-size: 13px;
                font-weight: 500;
                opacity: 70%;
            }
        }
    }

    .sending_amount {
        border-radius: 4px;
        padding: 8px 10px;
        box-shadow: 0px 0px 4px 2px #BAEAC8 inset;
        color: $text-white;
        font-size: 14px;
        font-weight: 500;
    }

    .selected_address {
        font-size: 13px;
        color: $text-white;
        word-wrap: break-word;
        white-space: pre-wrap;
        text-align: left;
        opacity: 70%;
    }
}

.custom_option_img {
    width: 24px;
    height: 24px;
    object-fit: 'cover';
    border-radius: 100%;
    display: block;
}