@import '../../general__style/var';

.event__card {
  position: relative;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .event__img__container {
    width: 100%;
    position: relative;
    border-radius: 8px;
    aspect-ratio: 2.285;
  }

  .event__img {
    display: block;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 2.285;
    border-radius: 8px;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.45%, #000000 97.48%);
    border-radius: 8px;
  }

  .card_action_images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .2rem;
    position: absolute;
    top: 12px;
    left: 10px;
    right: 10px;

    .left_section {
      display: flex;
      gap: .2rem;
    }

    img {
      width: 20px;
      object-fit: contain;
      aspect-ratio: 1;
    }

    .alram {
      background-color: #ffcaca;
      padding: 3px 5px;
      border-radius: 10px;
      display: flex;
    }

    .usertesti {
      background-color: #3b3b3c;
      padding: 3px 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 22px;
      color: #ffffff;
    }

    .friend__playing {
      background-color: #cae9ff !important;
      color: #2a2a2b !important;
    }

    .dark {
      background-color: #baeac8;
      padding: 3px 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 22px;
      color: #3b3b3c;
    }

    .dots {
      background-color: #3b3b3c;
      padding: 7px 9px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 22px;
      color: #3b3b3c;
    }
  }

  .text {
    font-size: 16px;
    font-weight: 700;
    color: $text-white;
    position: absolute;
    bottom: 2%;
    text-align: center;
    left: 10px;
    right: 10px;
    min-height: 24px;

    p {
      line-height: 1;
      word-break: normal;
      white-space: normal;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }

  }
}