.dash__content__top {
 
  .trending_card_container {
    display: flex;
    overflow-x: auto;
    column-gap: 1rem;
    .images {
      position: relative;
      .card_top_images {
        position: absolute;
        left: 45px;
        top: 8px;
        .userM {
          width: 20px;
        }
      }
      .text {
        position: absolute;
        top: 105px;
        left: 7rem;
      }
      .event__img {
        max-width: 345px;
        object-fit: cover;
        aspect-ratio: 345/151;
      }
    }
  }
  .trending_card_container::-webkit-scrollbar {
    display: none;
  }
}


.container {
  max-width: 100%;
  max-height: 100vh; // Set the maximum height as needed

  // Maintain the aspect ratio
  width: 100%;
  padding-bottom: calc(151 / 345 * 100%);

  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}