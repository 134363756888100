@import "../../../general__style/var";

.header {
  background-color: $bg-color;
  color: $text-white;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column !important;
  }

  .header__left {
    width: 55%;
    padding-left: 4rem;

    @media screen and (max-width: 768px) {
      width: 40%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      padding-left: 0;
      position: relative;
    }

    img {
      width: 90px;
    }

    a {
      @media screen and (max-width: 600px) {
        position: absolute;
        top: 9px;
      }
    }
  }

  .header__right {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      gap: 1rem;
    }

    .center__content {
      display: flex;
      align-items: center;
      width: 320px;
      padding: 0px 12px;
      border-radius: 12px;
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.08);
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        opacity: 80%;

        path {
          fill: $active-buttons-bg;
        }
      }

      input {
        background: none;
        border: none;
        padding-left: 10px;
        color: $text-white;

        &:focus {
          outline: none;
        }
      }

      @media screen and (max-width: 540px) {
        width: 100%;
      }

      .antd__select__searchbar {
        box-shadow: none !important;
        outline: none !important;

        .ant-select-selector {
          background-color: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          padding: 0px 5px !important;
          height: 2rem !important;
          color: $text-white !important;

          .ant-select-selection-placeholder {
            color: $active-buttons-bg;
            text-align: left;
            padding: 5px !important;
            opacity: 50%;
          }
        }
      }
    }

    .hide_search_bar {
      display: flex;
      @media screen and (max-width: 540px) {
        display: none !important;
      }
    }

    .right__content {
      display: flex;
      column-gap: 1rem;

      img {
        cursor: pointer;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: flex-end;
        column-gap: 10px;
      }

      .signup {
        background-color: #caffd9;
        border: none;
        padding: 8px 18px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.custom__dropdown_container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  // border: 1px solid rgba(255, 248, 248, 0.5);
  padding: 4px 0px;
  min-height: 4rem;
}

.custom__dropdown {
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 4px;
  background-color: $dark-gray;

  .item_container {
    padding-top: 2px;
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;

  }

  .item__img {
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1;
  }

  .item__img__event {
    width: 2rem;
    border-radius: 20%;
    object-fit: cover;
    height: 1.4rem;
  }

  .label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: white;
  }

  .type {
    position: absolute;
    top: -2px;
    right: 4px;
    color: white;
    opacity: .7;
    font-size: 8px;
  }
}