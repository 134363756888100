.review_header {
  font: 700 20px / calc(24.2 / 12) inter !important;
}

.review_table_head p {
  font: normal 12px / calc(14 / 12) poppins;
  color: #ffffffb2;

  .info_svg {
    position: absolute;
    top: -8px;
  }
}

.submit-btn {
  width: 50%;
  padding: .5rem .4rem;
}