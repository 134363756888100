@import "../../general__style/var";

.profile__edit {
  display: flex;
  width: 100%;

  .backicon {
    width: 30px;
    margin-left: 5.7rem;

    @media screen and (max-width: 540px) {
      margin-left: 2rem;
    }
  }

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .profile__left__edit {
    width: 100%;

    .profile__left__content {
      width: 70%;
      text-align: center;
      margin: auto;
      padding-top: 3.5rem;

      @media screen and (max-width: 540px) {
        width: 90%;
        padding-top: 1.5rem;
      }

      .profile__img {
        display: flex;
        justify-content: center;

        .image_container {
          width: 20%;
          position: relative;

          .img {
            width: 100%;
            border-radius: 50%;
            aspect-ratio: 1;
            object-fit: cover;
            margin-bottom: 1rem;
          }
        }
      }

      .leaderboards__tabs_edit {
        background-color: $dark-gray;
        width: fit-content;
        padding: 6px 7px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: 0.7rem !important;
        margin: auto;

        .box__tabs {
          display: flex;
          column-gap: 1.5rem;

          a {
            text-decoration: none;
            color: $text-white;
            padding: 3px 5px;
          }

          a.active {
            background-color: #baeac8;
            border-radius: 6px;
            color: black;
            font-weight: bold;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 600px) {
          padding: 6px 6px;
        }
      }

      .buttons__two {
        display: flex;
        width: 85%;
        margin: auto;
        gap: 1px;
        justify-content: center;

        @media screen and (max-width: 540px) {
          width: 90%;
        }

        .add_friend {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding-left: 23px;
          font-size: 14px;
          padding-top: 11px;
          cursor: pointer;
        }

        .links {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 0.4rem;
          padding-left: 20px;
          font-size: 14px;
          padding-top: 11px;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .description {
        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.3);
          width: 80%;
          margin: auto;

          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }

        span {
          display: flex;
          text-align: center;
          justify-content: center;
          width: 45%;
          margin: auto;
          padding: 8px 0;
          gap: 1rem;
          border-radius: 10px;
          border: 3px solid rgba(0, 0, 0, 0.25);
          background-color: #cae9ff;

          h6 {
            font-size: 16px;
            font-weight: 700;
            color: rgba(42, 42, 43, 1);
          }
        }
      }

      .social__links {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: auto;
        gap: 0.7rem;
        padding-top: 1.5rem;

        span {
          background-color: #cae9ff;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  .profile__right__edit {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: $text-white;

    .edit__form {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: auto;

      .copy_button {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $text-white;
        font-size: 14px;
        font-weight: 600;
        background-color: $dark-gray;
        cursor: pointer;
        margin-bottom: 1rem;
        padding: 8px;
        border-radius: 4px;
      }

      .userName {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          font-weight: 500;
          padding-left: 5px;
        }

        input {
          background-color: $dark-gray;
          border: none;
          padding: 13px 10px;
          border-radius: 10px;
          color: $text-white;

          ::placeholder {
            color: $text-white;
          }

          &:focus {
            outline: none;
          }
        }

        .error_email {
          color: red;
          font-size: 12px;
          padding-left: 5px;
        }
      }

      .email {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        .labels {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 5px;
        }

        label {
          font-size: 14px;
          font-weight: 500;
          padding-left: 1px;
        }

        input {
          background-color: $dark-gray;
          border: none;
          padding: 13px 10px;
          border-radius: 10px;
          color: $text-white;

          ::placeholder {
            color: $text-white;
          }

          &:focus {
            outline: none;
          }
        }

        .error_email {
          color: red;
          font-size: 12px;
          padding-left: 5px;
        }
      }

      .edit__description {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        label {
          font-size: 14px;
          font-weight: 500;
          padding-left: 6px;
        }

        .edit_desc_textarea {
          background-color: $dark-gray;
          border: none;
          padding: 13px 10px;
          border-radius: 10px;
          color: $text-white;

          ::placeholder {
            color: red;
          }

          &:focus {
            outline: none;
          }
        }

        .error_email {
          color: red;
          font-size: 12px;
          padding-left: 5px;
        }
      }

      .public__private {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .phone_container {
        .labels {
          display: flex;
          justify-content: space-between;

          label {
            font-size: 14px;
            font-weight: 500;
            padding-left: 6px;
          }
        }

        .phone_input_container {
          padding: 8px 12px;
          border-radius: 10px;
          background-color: $dark-gray;

          .PhoneInputInput {
            padding: 2px 8px;
            background-color: $dark-gray;
            color: $text-white;
            font-size: 14px;
            font-weight: 500;
            border: none;
            outline: none;
          }
        }
      }

    }
  }
}