@import "../../../general__style/var";

.popups__btn {
  background-color: $active-buttons-bg;
  border: none;
  padding: 10px 20px;
  width: 98%;
  margin: auto;
  border-radius: 10px;
  color: $active-text-color;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}
