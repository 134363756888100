@import "../../../general__style/var";

.input__groups__outcome {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  label {
    color: white;
  }
  textarea {
    background-color: $bg-color;
    border: none;
    outline: none;
    padding: 14px;
    border-radius: 10px;
    color: white;
    ::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.popups__btn {
  background-color: $active-buttons-bg;
  border: none;
  padding: 10px 20px;
  width: 98%;
  margin: auto;
  border-radius: 10px;
  color: $active-text-color;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}
