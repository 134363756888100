@import "../../../general__style/var";

h5 {
    background-color: rgba(28, 28, 32, 1);
    color: white;
    margin-top: -12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.link_container {
    display: flex;
    justify-content: center;
    gap: 4px;
    flex-direction: column;

    .link_action_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;
        padding-left: 12px;

        .link_action_container_name {
            color: white;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .link_name_container {
        color: white;
        font-size: 14px;
        font-weight: 500;
        background-color: $dark-gray;
        border-radius: 5px;
        padding: 12px;
        text-align: start;
        word-break: break-all;
    }

    .link_container {
        color: white;
        font-size: 10px;
        font-weight: 400;
        background-color: $dark-gray;
        border-radius: 5px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 1px;
        padding-bottom: 2px;
        text-align: start;
        word-break: break-all;
    }

}


.input_group {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 8px;
    margin-top: 24px;
    box-shadow: 0px 0px 4px 0px $active-buttons-bg;


    label {
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding-left: 10px;
        text-align: left;
    }

    input {
        background: $dark-gray;
        border: none;
        height: 40px;
        padding-left: 1rem;
        border-radius: 10px;
        color: white;

        ::placeholder {
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
        }

        &:focus {
            outline: none;
        }
    }

    .error_email {
        color: red;
        font-size: 12px;
        padding-left: 10px;
    }
}

.create_container {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding-top: 4px;
    cursor: pointer;

    .image_container {
        padding: 4px 0px 4px 8px;
    }
}

.link_modal_container {
    overflow-y: auto;
    height: 40vh;
    display: flex;
    flex-direction: column;
}