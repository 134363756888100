@import "../../general__style/var";

.profile {
  display: flex;
  width: 100%;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .profile__left {
    width: 100%;
    position: relative;

    .profile_edit {
      position: absolute;
      right: 99px;
      cursor: pointer;

      @media screen and (max-width: 540px) {
        right: 36px;
      }
    }

    .profile__left__content {
      width: 70%;
      text-align: center;
      margin: auto;
      padding-top: 3.5rem;

      @media screen and (max-width: 540px) {
        width: 90%;
      }

      .profile__img {
        display: flex;
        justify-content: center;

        .image_container {
          width: 20%;
          position: relative;

          img {
            width: 100%;
            margin-bottom: 1rem;
            border-radius: 50%;
            aspect-ratio: 1;
            object-fit: cover;
          }
        }
      }

      .leaderboards__tabs {
        background-color: $dark-gray;
        width: fit-content;
        padding: 6px 7px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin: auto;

        .box__tabs {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          column-gap: .5rem;

          a {
            text-decoration: none;
            color: $text-white;
            padding: 3px 5px;
          }

          a.active {
            background-color: #baeac8;
            border-radius: 6px;
            color: black;
            font-weight: bold;
            cursor: pointer;

            svg path {
              fill: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 600px) {
          padding: 6px 6px;
        }
      }

      .description {
        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.3);
          width: 80%;
          margin: auto;

          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }

        span {
          display: flex;
          text-align: center;
          justify-content: center;
          width: 45%;
          margin: auto;
          padding: 8px 0;
          gap: 1rem;
          border-radius: 10px;
          border: 3px solid rgba(0, 0, 0, 0.25);
          background-color: #cae9ff;

          h6 {
            font-size: 16px;
            font-weight: 700;
            color: rgba(42, 42, 43, 1);
          }
        }
      }

      .buttons__two {
        display: flex;
        width: 89%;
        margin: auto;
        gap: .8rem;

        .add_friend {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1.4rem;
          padding-left: 30px;
          font-size: 14px;
          padding-top: 11px;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .links {
          width: 45%;
          display: flex;
          align-items: center;
          gap: 0.4rem;
          padding-left: 19px;
          font-size: 14px;
          padding-top: 11px;
          cursor: pointer;

          @media screen and (max-width: 540px) {
            padding-left: 17px;
            width: 42%;
          }

          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 6px;
            color: #2a2a2b;
            cursor: pointer;
          }
        }
      }

      .button_single {
        display: flex;
        justify-content: center;
        width: 89%;
        margin: auto;
        margin-top: 1rem;
      }

      .social__links {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: auto;
        gap: 0.7rem;
        padding-top: 1.5rem;

        span {
          background-color: #cae9ff;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  .profile__right {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;

    h2 {
      color: #f4f3fc;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 2rem;
    }

    .images {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;

      .marvel2 {
        width: 90%;
        margin: auto;
      }

      .card_top_images {
        position: absolute;
        left: 8%;
        top: 12px;
      }

      .text {
        position: absolute;
        top: 80%;
        left: 33.5%;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;

        @media screen and (max-width: 540px) {
          left: 28.5%;
        }
      }
    }

    .four__buttons {
      margin-bottom: 2rem;

      .four__buttons__top {
        display: flex;
        justify-content: center;
        gap: 1px;

        .four__buttons__top__one {
          background-color: #ffcaca;
          width: 35%;
          text-align: center;
          padding: 4px 0;
          border-bottom: 1px solid black;

          @media screen and (max-width: 540px) {
            width: 35%;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            color: #2a2a2b;

            @media screen and (max-width: 540px) {
              font-size: 12px;
            }
          }

          .price__dollar__img {
            display: flex;
            justify-content: center;
            column-gap: 4px;

            p {
              font-size: 15px;
              font-weight: 600;
              color: #2a2a2b;

              @media screen and (max-width: 540px) {
                font-size: 14px;
              }
            }
          }
        }

        .four__buttons__top__two {
          background-color: #cae9ff;
          width: 35%;
          text-align: center;
          padding: 4px 0;
          border-bottom: 1px solid black;
          position: relative;

          @media screen and (max-width: 540px) {
            width: 35%;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            color: #2a2a2b;
          }

          .price__dollar__img {
            display: flex;
            justify-content: center;
            column-gap: 4px;
          }

          p {
            font-size: 15px;
            font-weight: 600;
            color: #2a2a2b;

            @media screen and (max-width: 540px) {
              font-size: 12px;
            }
          }

          .i {
            position: absolute;
            top: 4px;
            right: 5px;
          }
        }
      }

      .four__buttons__bottom {
        display: flex;
        justify-content: center;
        gap: 1px;

        .four__buttons__bottom__one {
          background-color: #ffcaca;
          width: 45%;
          text-align: center;
          padding: 4px 0;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;

          p {
            font-size: 14px;
            font-weight: 600;
            color: #2a2a2b;

            @media screen and (max-width: 540px) {
              font-size: 12px;
            }
          }

          .price__dollar__img {
            display: flex;
            justify-content: center;
            column-gap: 4px;

            p {
              font-size: 15px;
              font-weight: 600;
              color: #2a2a2b;
            }
          }
        }

        .four__buttons__bottom__two {
          background-color: #cae9ff;
          width: 45%;
          text-align: center;
          padding: 4px 0;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          position: relative;

          p {
            font-size: 14px;
            font-weight: 600;
            color: #2a2a2b;
          }

          .price__dollar__img {
            display: flex;
            justify-content: center;
            column-gap: 4px;
          }

          p {
            font-size: 15px;
            font-weight: 600;
            color: #2a2a2b;

            @media screen and (max-width: 540px) {
              font-size: 12px;
            }
          }

          .i {
            position: absolute;
            top: 4px;
            right: 5px;
          }
        }
      }
    }

    .bottom__content {
      margin-top: 1rem;
    }
  }
}

.private_profile {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.width_50 {
  width: 49%;

  img {
    max-width: 20px;
  }
}