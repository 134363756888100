.trending_images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .images {
    margin-top: 0;

    .bg {
      width: 100%;
    }
  }

}

.loadmore__container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}