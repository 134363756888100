@import "../../general__style/var";

.admin__specialUser__category {
  display: flex;
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
  .admin__left {
    width: 100%;
    .content {
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      @media screen and (max-width: 540px) {
        width: 95%;
      }
      label {
        color: white;
        padding-bottom: 4px;
      }
      .fees_optional {
        position: absolute;
        right: 0;
        top: 8px;
        font-size: 13px;
        padding-right: 4px;
        color: rgba(255, 255, 255, 0.5);
      }
      input {
        background-color: #3b3b3c;
        padding: 15px;
        border: none;
        border-radius: 10px;
        color: white;
        &:focus {
          outline: none;
        }
        ::placeholder {
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .ddeposit__four__butons {
        width: 100%;
        margin-bottom: 1rem !important;
        margin: auto;
        .up__btn {
          display: flex;
          border-radius: 10px;
          margin-top: 1rem;
          @media screen and (max-width: 540px) {
            margin-top: 2rem;
          }
          span {
            padding: 10px 10px;
            text-align: center;
            margin: auto;
            width: 48%;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 600;
            @media screen and (max-width: 540px) {
              padding: 10px 0;
            }
          }
        }
        .up__btn.active {
          background-color: red;
        }
        .down__btn {
          display: flex;
          border-radius: 10px;
          margin-top: 0.6rem;
          span {
            padding: 10px 10px;
            text-align: center;
            margin: auto;
            width: 48%;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .admin__right__category {
    width: 100%;
    .center__content {
      background-color: transparent;
      display: flex;
      width: 80%;
      margin-top: 1rem !important;
      margin: auto;
      padding: 14px 10px;
      border-radius: 20px;
      border: 2px solid rgba(255, 248, 248, 0.5);
      img {
        cursor: pointer;
      }
      input {
        background: none;
        border: none;
        padding-left: 10px;
        color: $text-white;
        &:focus {
          outline: none;
        }
      }
      @media screen and (max-width: 600px) {
        width: 93%;
      }
    }

    .input__groups__category {
      width: 100%;
      select {
        background-color: #3b3b3c;
        height: 51px;
        border: none;
        color: #ffffff;
        padding: 0 1rem;
        border-radius: 10px;
        width: 80%;
        margin-bottom: 1rem !important;
        margin: auto;
        display: flex;
        :focus {
          outline: none;
        }
        @media screen and (max-width: 540px) {
          width: 90%;
        }
      }
      .select {
        background-color: #3b3b3c;
        width: 90%;
        margin: auto;
        padding: 13px 10px;
        border-radius: 10px;
        display: flex;
        gap: 10px;
        label {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .select__icon {
        background-color: #3b3b3c;
        width: 90%;
        margin: auto;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 1px;
        label {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .btn {
        width: 45%;
        margin-bottom: 1rem !important;
        margin-top: 1rem !important;
        margin: auto;
        .create {
          background-color: #baeac8;
          padding: 11px 20px;
        }
      }
    }

    .box__one__category {
      background-color: #3b3b3c;
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-bottom: 1rem !important;
      margin: auto;
      padding: 7px 10px;
      border-radius: 11px;
      @media screen and (max-width: 540px) {
        width: 93%;
      }
      .box__one__left {
        display: flex;
        align-items: center;
        column-gap: 6px;
        h3 {
          background-color: #cad6ff;
          padding: 3px 11px;
          border-radius: 9px;
          font-size: 14px;
          font-weight: 600;
        }
        p {
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .box__one__right_category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 9%;
        margin-top: 10px;
        @media screen and (max-width: 540px) {
          width: 11%;
        }
      }
    }
  }
}
