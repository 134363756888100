@import "../../general__style/var";

 
input.custom_checkbox[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: transparent;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid transparent;
    border-radius: 4px;
    display: grid;
    place-content: center;
    cursor: pointer;
}

input.custom_checkbox[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em black;
    /* Windows High Contrast Mode */
    background-color: transparent;
}

input.custom_checkbox[type="checkbox"]:checked {
    background-color: $active-buttons-bg;
}

input.custom_checkbox[type="checkbox"]:checked::before {
    transform: scale(1);
}

input.custom_checkbox[type="checkbox"]:focus {
    outline: max(2px, 0.15em) solid transparent;
    outline-offset: max(2px, 0.15em);
}