.vertical__buttons__popups {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  row-gap: 12px;
  padding-top: 18px;

  .vertical__buttons__first__pop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cae9ff;
    padding: 12px 6px;
    border-radius: 12px;
    column-gap: 0.3rem;
    flex-grow: 1;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #2a2a2b;
    }
  }

  .vertical__buttons__secound__pop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cae9ff;
    padding: 12px 8px;
    border-radius: 12px;
    column-gap: 0.6rem;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #2a2a2b;
    }
  }

  .vertical__buttons__third__pop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cae9ff;
    padding: 12px 8px;
    border-radius: 12px;
    column-gap: 0.6rem;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #2a2a2b;
    }
  }

  .edit__event__continer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-right: 54px;
  }

  .horizontal_two {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .icon_img {
    min-height: 2.6rem;
  }
}

.update__link__btn {
  border: none;
  padding: 10px 30px;
  width: auto;
  margin-left: auto;
  border-radius: 10px;
  color: #000000;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
  margin-top: 8px;
}