@import "../../general__style/var";

.custom_button {
  color: $text-white;
  background-color: transparent;
  border: none;
  padding: 15px 20px;
  width: 100%;
  margin: auto;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.25);

  @media screen and (max-width: 540px) {
    padding: 10px 4px;
  }
}

.custom_button.outline_pink {
  color: $active-buttons-bg-pink;
  background-color: transparent;
  box-shadow: 0px 0px 4px 0px $dark-pink;
}

.custom_button.outline_cyan {
  color: $active-buttons-bg;
  background-color: transparent;
  box-shadow: 0px 0px 4px 0px $active-buttons-bg;
}

.custom_button.border_outline_cyan {
  color: $active-buttons-bg;
  background-color: $input-bg;
  border: 2px solid $active-buttons-bg;
  border-radius: 8px;
  width: fit-content;
  padding: 4px 24px;
}

.custom_button.dark_outline_cyan {
  color: $active-buttons-bg-dark;
  background-color: transparent;
  box-shadow: 0px 0px 4px 0px $active-buttons-bg-dark;
}

.custom_button.cyan {
  color: $active-text-color;
  background-color: $active-buttons-bg;
}

.custom_button.dark_cyan {
  color: $active-text-color;
  background-color: $active-buttons-bg-dark;
}

.custom_button.pink {
  color: $active-buttons-bg-pink;
  background-color: $dark-pink;
}

.custom_button.green {
  color: $active-buttons-bg-dark;
  background-color: $dark-green;
}

.custom_button.light_gray {
  color: $active-buttons-bg-dark;
  background-color: $light-gray;
}

.custom_button.small {
  font-size: 14px;
  padding: 10px 4px;

  @media screen and (max-width: 540px) {
    padding: 8px 4px;
  }
}

.fill_current_color {
  svg {
    max-width: 20px;
    max-height: 20px;

    path {
      fill: currentColor;
    }
  }
}

.stroke_current_color {
  svg {
    max-width: 20px;
    max-height: 20px;

    path {
      stroke: currentColor;
    }
  }
}

.bg_dark {
  background-color: $active-buttons-bg-dark;
}

.bg_pink {
  background-color: $active-buttons-bg-pink;
}

.padding_x_xs {
  padding: 15px 6px;

  @media screen and (max-width: 540px) {
    padding: 10px 4px;
  }
}

.padding_xs {
  padding: 4px 6px;
}

.h_full {
  height: 100%
}

.w_half {
  width: 50%;
}

.h_41 {
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.bg_dark_gray {
  background-color: $input-bg;
}

.light_green {
  background-color: $dark-green;
  color: $active-buttons-bg-dark
}

.max_width_30vh {
  max-width: 30vw;
}