.custom_button_com {
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
  background-color: #cae9ff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.25) inset;
  font-weight: 700;
  color: #2a2a2b;
  height: 44px;
  cursor: pointer;
}
