@import '../../../general__style/var.scss';

.heading {
  color: #cae9ff;
  margin: 0;
}

.para {
  color: white;
  width: 80%;
  margin: auto;
  font-size: 12px;
  font-weight: 400;
}

.your_play {
  margin: 1.5rem 0;

  .label {
    color: white;
    margin-left: 0.8rem;
    font-weight: 600;
    font-size: 14px;

    @media screen and (max-width: 540px) {
      font-size: 12px;
    }
  }

  .input_field_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: $dark-gray;
    border-radius: 4px;
    padding: 10px 1rem;
    box-shadow: 0px 0px 4px 2px $active-buttons-bg-dark inset;

    .input_field {
      width: 98%;
      position: relative;
      border-right: 1px solid rgba(255, 255, 255, 0.8);

      .your_play_input {
        background: transparent;
        border: none;
        color: $text-white;
        width: 100%;
        font-weight: 500;

        ::placeholder {
          color: rgba(255, 255, 255, 0.8);
        }

        &:focus {
          outline: none;
        }
      }

      span {
        color: rgba(255, 255, 255, 0.8);
        position: absolute;
        right: 12px;
      }

    }

    #odd_value {
      min-width: 65px;
      background: #baeac8;
      backdrop-filter: blur(100px);
      height: 28px;
      line-height: 1.8;
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 10px;
    }
  }


  .expected_odds {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
  }

  .bottom_Desc {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    margin-right: 0.8rem;
  }
}

.bottom__desc {
  width: 98%;
  margin: 24px auto 8px auto !important;

  .potential_win {
    font-size: 12px;
    font-weight: 600;
    color: $text-white;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0px 16px 0px 16px;

    .amount {
      color: $active-buttons-bg-dark;
    }
  }
}