@import '../../general__style/var.scss';

.MuiModal-root .popup {
    max-height: 100%;
    min-height: 10rem;
    overflow-y: auto;
    background-color: $bg-color;
    border: none !important;
    border-radius: 10px !important;
    text-align: center;
    position: relative;

    &:focus-visible {
        outline: none;
    }

    @media screen and (max-width: 540px) {
        width: 95%;
    }

    .heading_container {
        display: flex;
        column-gap: .5rem;
        position: relative;

        .modal_heading {
            color: white;
            width: 100%;
            min-height: 26px;
            font-size: 20px;
            font-weight: 700;
            padding-left: 30px;
            padding-right: 30px;

            @media screen and (max-width: 540px) {
                font-size: 16px;
            }
        }

        .right_side_extra {
            position: absolute;
            top: -8px;
            right: 0;
        }
    }

    .close_button {
        position: absolute;
    }

    .image-container {
        position: absolute;
        display: grid;

        .read_more {
            padding-top: 0px;
        }

        @media screen and (max-width: 540px) {
            top: 14px;
        }
    }

    .date__amt {
        background-color: $bg-color;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;
        color: white;
    }

    .popup_text {
        color: #cae9ff;
    }

    .date__amt__head {
        display: flex;
        justify-content: space-between;
        padding: 0 7px;
        margin-top: 2rem;

        span {
            color: rgba(255, 255, 255, 0.8);
        }
    }

    .down__text__claim {
        color: rgba(255, 255, 255, 0.8);
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
    }
}