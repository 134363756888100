@import '../../../general__style/var.scss';

.box__one__cancelRefund {
  background-color: $dark-gray;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  margin: auto;
  padding: 7px 10px;
  border-radius: 11px;

  @media screen and (max-width: 540px) {
    width: 93%;
  }
}

.refund_challenge_confirmation {
  width: 100%;
  .refund_challenge_container {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
    .challenge_container {
      width: 100%;
    }
  }

}

.cancel_refund_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    gap: .5rem;
  }
}

.image-image_container_refund {
  width: fit-content;
}