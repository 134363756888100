@import "../../../general__style/var";

.input__groups__stacking {
  width: 100%;
  label {
    width: 90%;
    margin: auto;
    gap: 1rem;
    border-radius: 10px;
    background-color: $bg-color;
    padding: 10px;
    display: flex;
    justify-content: center;
    color: white;
  }
  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    padding-right: 20px;
  }
}

.input__groups__stacking_two {
  width: 100%;
  label {
    width: 100%;
    margin-top: 1rem !important;
    margin: auto;
    gap: 1rem;
    border-radius: 10px;
    background-color: $bg-color;
    padding: 10px;
    display: flex;
    justify-content: center;
    color: white;
  }
  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    font-weight: 500;
  }
}
.extrafee {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
  padding: 0 1rem;
  span {
    color: rgba(255, 255, 255, 0.8);
  }
}

.popups__btn_st {
  box-shadow: inset 0px 0px 3px 5px rgba(0, 0, 0, 0.25);
  background-color: $active-buttons-bg;
  border: none;
  padding: 10px 20px;
  width: 98%;
  margin: auto;
  border-radius: 10px;
  color: $active-text-color;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}
