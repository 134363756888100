@import "../../../general__style/var";
h5 {
  background-color: rgba(28, 28, 32, 1);
  color: white;
  margin-top: -12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.popups__btn {
  background-color: #caffd9;
  border: none;
  padding: 10px 0px;
  width: 95%;
  margin-top: 2rem !important   ;
  margin: auto;
  border-radius: 10px;
  color: $active-text-color;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}
