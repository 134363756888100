@import "../../general__style/var";

.box__divs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    row-gap: 16px;
    column-gap: 12px;
    background-color: $dark-gray;
    padding: 8px;
    border-radius: 4px;

    .width_half {
        width: calc(50% - 6px);
    }

    .width_60 {
        width: calc(60% - 6px);

        img {
            max-width: 20px;
        }
    }

    .width_40 {
        width: calc(40% - 6px);

        img {
            max-width: 20px;
        }
    }

    .organizer_button {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 12px;
        position: relative;
        gap: 8px;
        color: $text-white;

        .img_container {
            flex-shrink: 0;
            width: 48px;
            height: 48px;

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 50%;
                aspect-ratio: 1;
            }
        }

        .title {
            font-size: 10px;
            font-weight: "600";
            line-height: unset
        }

        .username {
            font-size: 14px;
            font-weight: 700;
            display: inline-block;
            max-width: 100%;
            line-height: unset;
            display: flex;
            align-items: center;
        }

        .profile_badge {
            width: 24px;
            height: 24px;
            margin-left: 4px;
        }

        span {
            font-size: 12px;
            color: white;
        }

        .text__event {
            font-size: 12px;
            font-weight: 400;
            color: $text-white;
            text-align: left;
        }
    }

    .single_action_button {
        width: 100%;
    }

    @media screen and (max-width: 540px) {

        .organizer_button {
            padding: 2px 6px;
        }

        .title {
            line-height: 1
        }

        .username {
            line-height: 1.25
        }
    }
}