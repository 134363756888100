@import "../../general__style/var";

.custom_tabs_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 4px 8px;
    background-color: $dark-gray;

    .tab {
        flex: 1;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        color: $text-white;
        padding: 4px 16px;
    }

    .tab.active {
        color: $active-buttons-bg;
        background-color: $light-gray;
        border-radius: 4px;
    }
}

.custom_tabs_container.small {
    padding: 2px 6px;

    .tab {
        font-size: 12px;
        padding: 2px 8px;
    }
}