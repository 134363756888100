.cards_top__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    margin-top: 1.5rem;

    .title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
    }
}