.block_container {
  .box__one {
    background-color: #3b3b3c;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 1rem;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__one__left {
      display: flex;
      align-items: center;
      column-gap: 6px;

      h3 {
        background-color: #cad6ff;
        padding: 3px 11px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .box__one__right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 540px) {
        width: 17%;
      }

      .span_one {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: rgba(186, 234, 200, 1);
        color: white;
        padding: 6px 17px;
        border-radius: 11px;
        gap: 4px;

        @media screen and (max-width: 540px) {
          background: none;
        }

        p {
          font-size: 12px;
          font-weight: 700;
          color: #2a2a2b;
        }
      }

      .span_two {
        background: #ffcaca;
        display: flex;
        align-items: center;
        padding: 6px 20px;
        border-radius: 11px;

        p {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}