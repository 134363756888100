.custom-stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 50%;
  margin: auto;
}

.steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.step {
  padding: 0px 9px;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: #cae9ff;
    color: #2a2a2b;
    font-weight: 700;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    margin: 0 10px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #cae9ff;
      color: #2a2a2b;
      font-weight: bold;
    }
  }
}
