@import "../../general__style/var";


.events_header_info_container {
  width: 50%;
  margin: auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @media screen and (max-width: 540px) {
    width: 95%;
  }

  .wallet_box {
    background: linear-gradient(180deg, #0D0D0E 0%, #363E45 100%);
    color: $active-buttons-bg;
  }

  .positions_box {
    background: linear-gradient(180deg, #0D0D0E 0%, #27362C 100%);
    color: $active-buttons-bg-dark;
    align-items: end;
  }

  .header_info_box_common {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    border-radius: 4px;
  }



  .title {
    font-size: 14px;
    font-weight: 700;
    color: $text-white;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .currency {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .status {
    font-size: 10px;
    font-weight: 500;
  }
}

.my__games {
  margin-top: 2rem;
  margin-bottom: 4rem !important;
  width: 95%;
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 95%;
  }

  .tabs_container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 540px) {
      max-width: 100%;
    }
  }

  h3 {
    color: #f4f3fc;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 2rem;
  }

  .images {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }

    .marvel2 {
      width: 100%;

      @media screen and (max-width: 540px) {
        width: 100%;
      }
    }

    .card_top_images {
      position: absolute;
      top: 12px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 540px) {
        width: 23%;
        padding-left: 14px;
      }

      .alram {
        background-color: #ffcaca;
        padding: 3px 5px;
        border-radius: 10px;
        display: flex;
      }

      .usertesti {
        background-color: #cae9ff;
        padding: 3px 5px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 22px;
        color: #2a2a2b;
      }

      .dark {
        background-color: #baeac8;
        padding: 3px 5px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 22px;
        color: #3b3b3c;
      }

      .dots {
        background-color: #3b3b3c;
        padding: 7px 9px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 22px;
        color: #3b3b3c;
      }
    }

    .text {
      position: absolute;
      top: 80%;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;

      p {
        -webkit-text-stroke: 1px black;
      }

      @media screen and (max-width: 540px) {
        left: 23.5%;
      }
    }
  }

  .organizer_event_card_container {
    margin-bottom: 1rem;
  }

  .bottom_info {
    margin-bottom: 2rem;

    .side_way_cards {
      display: flex;
      justify-content: center;
      gap: 1px;

      .left_side {
        width: 45%;
        text-align: center;
        padding: 4px 0;
        border: 1px solid #000000;

        @media screen and (max-width: 540px) {
          width: 45%;
        }

        p {
          font-size: 12px;
          font-weight: 600;
        }

        .price__dollar__img {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 4px;

          p {
            font-size: 14px;
            font-weight: 600;
          }
        }

        svg {
          path {
            stroke: currentColor;
            stroke-width: 1px;
          }
        }
      }

      .left_side.rounded {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .left_side.green {
        color: $active-buttons-bg-dark;
        background-color: $dark-green;
      }

      .left_side.pink {
        color: $active-buttons-bg-pink;
        background-color: $dark-pink;
      }

      .left_side.pink_gray {
        color: $active-buttons-bg-pink;
        background-color: $dark-pink;
      }

      .right_side {
        width: 45%;
        text-align: center;
        padding: 4px 0;
        border: 1px solid black;
        position: relative;

        @media screen and (max-width: 540px) {
          width: 45%;
        }

        p {
          font-size: 12px;
          font-weight: 600;
        }

        .price__dollar__img {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 4px;

          p {
            font-size: 14px;
            font-weight: 600;
          }
        }

        svg {
          path {
            stroke: currentColor;
            stroke-width: 1px;
          }
        }
      }

      .right_side.rounded {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .right_side.green {
        color: $active-buttons-bg-dark;
        background-color: $dark-green;
      }

      .right_side.pink {
        color: $active-buttons-bg-pink;
        background-color: $dark-pink;
      }

      .right_side.pink_gray {
        color: $active-buttons-bg;
        background-color: $gray;
      }
    }
  }

  .bottom_info.margin_0 {
    margin-bottom: 0rem;
  }

  .bottom__content {
    margin-top: 1rem;
  }

  .my__games__images {
    width: 80% !important;
  }
}

.my__games.user_events_container {
  width: 50%;

  @media screen and (max-width: 540px) {
    width: 95%;
  }
}

.challenges_review_and_claim {
  .title {
    text-align: center;
    color: $text-white;
    font-size: 20px;
    font-weight: 700;
    margin: 1.5rem auto;
  }
  .claim_button{
    margin: 4px auto 2rem auto;
  }
}