@import "../../general__style/var";

.stapper__form {
  width: 70%;
  // margin-top: 3rem !important;
  margin: 3rem auto;
  padding: 0 4rem;

  @media screen and (max-width: 540px) {
    width: 100%;
    padding: 0 1rem;
  }

  .input__groups {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    position: relative;

    .step_one_label {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      text-align: left;
      padding-left: 10px;
    }

    .step_one_label_optional {
      font-size: 12px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5);
      text-align: right;
      padding-left: 10px;
      padding-right: 10px;
    }

    input {
      background-color: $dark-gray;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: #ffffff;

      ::placeholder {
        color: #ffffff;
      }

      &:focus {
        outline: none;
      }
    }

    textarea {
      background-color: $dark-gray;
      border: none;
      height: 83px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;
      padding-top: 7px;

      ::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }

      &:focus {
        outline: none;
      }
    }

    .choose_timeline_button {
      background-color: $dark-gray;
      border: none;
      height: 40px;
      border-radius: 10px;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        outline: none;
      }

    }

    select {
      background-color: #3b3b3c;
      height: 40px;
      border: none;
      color: #ffffff;
      padding: 0 1rem;
      border-radius: 10px;

      :focus {
        outline: none !important;
      }
    }

    .step_one_label_optional_social {
      position: absolute;
      right: 1px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }

    .fees_info {
      font-size: 10px;
      display: flex;
      margin-left: auto;
      color: rgba(255, 255, 255, 0.8);
      line-height: 1;
      margin: 4px 10px 0px 0px;
      flex-shrink: 0;
    }

    .fees_input_container {
      width: 100%;
      background-color: $dark-gray;
      display: flex;
      border-radius: 10px;
      overflow-y: hidden;

      .counter_arrow_container {
        width: 36px;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        .counter_arrow_img {
          width: 16px;
          height: 16px;
          object-fit: contain;
          cursor: pointer;
        }

        .rotate_up {
          transform: rotate(180deg);
        }
      }

      .total_fees_container {
        margin: 4px 6px;
        padding: 2px 8px;
        background-color: $active-buttons-bg;
        border-radius: 10px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }

      .fees_input {
        flex-grow: 1;
        width: 100%;
      }

      .hr_separator {
        border: none;
        border-left: 2px solid rgba(255, 255, 255, 0.5);
        margin: 4px 10px
      }
    }
  }

  .input__groups_tags {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    position: relative;

    .step_one_label {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      text-align: left;
      padding-left: 10px;
    }

    input {
      background-color: #3b3b3c;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: #ffffff;

      ::placeholder {
        color: #ffffff;
      }

      &:focus {
        outline: none;
      }
    }

    select {
      background-color: #3b3b3c;
      height: 40px;
      border: none;
      color: #ffffff;
      padding: 0 1rem;
      border-radius: 10px;

      :focus {
        outline: none !important;
      }
    }

    .tag_plus {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 7px;
      width: 34px;
      cursor: pointer;

      @media screen and (max-width: 540px) {
        left: 10px;
      }
    }
  }

  .ant-select-selection-item-remove {
    color: #2a2a2b !important;
    padding-top: 4px;
  }

  .ant-select-multiple .ant-select-selection-item {
    height: 30px !important;
  }

  .ant-select-multiple .ant-select-selection-item-content {
    display: flex !important;
    align-items: center !important;
    font-weight: 700 !important;
  }

  .ant-select-selection-placeholder {
    color: #ffffff !important;
  }

  .ant-select-arrow {
    color: #ffffff !important;
  }

  .ant-select-selection-item {
    background-color: #cae9ff !important;
    color: #3b3b3c !important;
  }

  .create__event {
    padding: 10px 20px;
  }

  .MuiStepLabel-label .Mui-active .css-1hv8oq8-MuiStepLabel-label {
    color: white !important;
  }

  .MuiStepLabel-label .Mui-active .css-1hv8oq8-MuiStepLabel-label {
    color: white !important;
  }

  .MuiStepLabel-labelContainer {
    span {
      color: white;
    }

    color: white !important;
  }

  .css-1atslg0-MuiTypography-root {
    color: white !important;
  }

  .css-117w1su-MuiStepIcon-text {
    fill: #000 !important;
  }

  .MuiStepIcon-text .css-117w1su-MuiStepIcon-text {
    fill: black !important;
  }

  .css-4ff9q7.Mui-active {
    color: #cae9ff !important;
  }

  .css-4ff9q7 {
    color: #3b3b3c !important;
  }

  .css-4ff9q7.Mui-completed {
    background-color: #cae9ff !important;
    color: #2a2a2b !important;
  }

  .accept_label {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  }

  .public__private {
    background-color: $dark-gray;
    padding: 6px 0;
    border-radius: 10px;
    margin-bottom: 1rem;

    .box__tabs {
      display: flex;
      column-gap: 2rem;
      width: 95%;
      margin: auto;

      a {
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: $text-white;
        padding: 3px 5px;
      }

      a.active {
        background-color: #baeac8;
        border-radius: 6px;
        color: black;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .token_select_container {
    background-color: $dark-gray;
    padding: 4px;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;

    .token_box {
      display: flex;
      justify-content: center;
      color: white;
      font-weight: 600;
      padding: 4px 4px;
    }

    .active {
      background-color: $light-gray;
      border-radius: 6px;
      color: $active-buttons-bg;
    }
  }

  .input_group_name {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem !important;
    margin: auto;
    position: relative;

    label {
      color: white;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      padding-left: 10px;
      margin-bottom: 0.4rem;
    }

    input {
      background-color: $dark-gray;
      height: 40px;
      border: none;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;

      ::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }

    select {
      background-color: $dark-gray;
      height: 40px;
      border: none;
      color: #ffffff;
      padding: 0 1rem;
      border-radius: 10px;
      position: relative;
      outline: none !important;

      :focus {
        outline: none !important;
      }

      :after {
        content: "";
        background-color: white;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: black transparent transparent transparent;
        position: absolute;
        top: 9px;
        right: 6px;
      }
    }
  }

  .optional_absolute {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  .file_input {
    position: relative;
    margin-bottom: 1rem;

    #fileInput {
      font-size: 0;
    }

    input {
      background: $dark-gray;
      display: none;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;
      width: 100%;
    }

    label {
      margin-top: 2rem;
      display: flex;
      padding-left: 50px !important;
      align-items: center;
      background: $dark-gray;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;
      width: 100%;
      cursor: pointer;
    }

    .img_gal {
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 9px;
      width: 28px;
    }

    .optional {
      position: absolute;
      right: 8px;
      top: -20px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.5);
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }
  }
}

// STEP___THREE

.form__event {
  margin-top: 1rem;

  .top__divs {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .top__divs__left {
      span {
        display: flex;
        justify-content: center;
        background-color: #cae9ff;
        padding: 3px 6px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        color: #2a2a2b;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
      }
    }

    .top__divs__center {
      color: #ffffff;
    }

    .top__divs__right {
      span {
        display: flex;
        justify-content: center;
        background-color: #baeac8;
        padding: 3px 6px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        color: #2a2a2b;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
      }
    }
  }

  .box__head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__head__left_step_three {
      display: flex;
      padding-left: 1rem;
      font-size: 14px;
      font-weight: 400;
      gap: 0.5rem;
      color: rgba(255, 255, 255, 0.7);
      position: relative;

      .i {
        position: absolute;
        right: -14px;
        top: -2px;
      }
    }

    .box__head__right_step_three {
      position: relative;

      .span__Ps {
        display: flex;
        column-gap: 60px;
        padding-right: 10px;

        @media screen and (max-width: 1280px) {
          column-gap: 20px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .i {
        position: absolute;
        right: -3px;
        top: -2px;
      }
    }
  }

  .box__one__stepper {
    background-color: #3b3b3c;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__one__left {
      display: flex;
      align-items: center;
      column-gap: 6px;

      h3 {
        background-color: #cad6ff;
        padding: 3px 11px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        color: #2a2a2b;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .box__one__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 17%;

      @media screen and (max-width: 540px) {
        width: 25%;
      }

      .span_one {
        display: flex;
        align-items: center;
        background-color: #2a2a2b;
        color: white;
        padding: 6px 17px;
        border-radius: 11px;
        gap: 4px;

        p {
          font-size: 12px;
          font-weight: 600;
        }
      }

      .span_two_step_three {
        background: #baeac8;
        display: flex;
        align-items: center;
        padding: 6px 20px;
        border-radius: 11px;

        p {
          font-size: 12px;
          font-weight: 600;
          color: #2a2a2b;
        }
      }
    }
  }

  .box__two__stepper {
    background-color: #3b3b3c;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 1rem !important;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__one__left {
      display: flex;
      align-items: center;
      column-gap: 6px;

      h3 {
        background-color: #cad6ff;
        padding: 3px 11px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        color: #2a2a2b;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .box__one__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 17%;

      @media screen and (max-width: 540px) {
        width: 24%;
      }

      .span_two {
        background: #ffcaca;
        display: flex;
        align-items: center;
        padding: 6px 20px;
        border-radius: 11px;

        p {
          font-size: 12px;
          font-weight: 600;
          color: #2a2a2b;
        }
      }
    }
  }

  .bottom {
    width: 50%;
    margin-top: 1rem !important;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .terminate {
      background-color: #caffd9;
      padding: 12px 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-radius: 10px;
      margin-bottom: 0.7rem;
      color: #2a2a2b;
      cursor: pointer;
    }

    .cancel {
      background-color: #ffcaca;
      padding: 7px 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-radius: 10px;
      color: #2a2a2b;
      cursor: pointer;
    }
  }

  .challenges_container {
    padding: 1rem 0;

    @media screen and (max-width: 540px) {
      width: 90%;
      margin: auto;
    }
  }

  .action_buttons_container_organizer {
    width: 100%;
    margin-top: 2rem;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
      margin-top: 1rem;
    }
  }

}

//STEP__TWO

.form__event {
  margin-top: 1rem !important;
  margin-bottom: 3rem !important;
  width: 60%;
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 95%;
    margin: auto;
  }

  .top__divs {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .top__divs__left {
      span {
        display: flex;
        justify-content: center;
        background-color: #cae9ff;
        padding: 3px 6px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        color: #2a2a2b;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
      }
    }

    .top__divs__center {
      color: #ffffff;
    }

    .top__divs__right {
      span {
        display: flex;
        justify-content: center;
        background-color: #baeac8;
        padding: 3px 6px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        color: #2a2a2b;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
      }
    }
  }

  .box__head_step_two {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__head__left_step_two {
      display: flex;
      padding-left: 1rem;
      font-size: 14px;
      font-weight: 400;
      gap: 0.5rem;
      color: rgba(255, 255, 255, 0.7);
      position: relative;

      .i {
        position: absolute;
        right: -14px;
        top: -3px;
      }
    }

    .box__head__right_step_two {
      position: relative;

      .span__Ps {
        display: flex;
        column-gap: 60px;
        padding-right: 10px;

        @media screen and (max-width: 1280px) {
          column-gap: 20px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .i {
        position: absolute;
        right: -3px;
        top: -3px;
      }
    }
  }

  .box__one__stepper {
    background-color: #3b3b3c;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;
    margin-bottom: 1rem !important;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__one__left {
      display: flex;
      align-items: center;
      column-gap: 6px;

      h3 {
        background-color: #cad6ff;
        padding: 3px 11px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        color: #2a2a2b;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .box__one__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 17%;

      @media screen and (max-width: 540px) {
        width: 25%;
      }

      .span_one {
        display: flex;
        align-items: center;
        background-color: #2a2a2b;
        color: white;
        padding: 6px 17px;
        border-radius: 11px;
        gap: 4px;

        p {
          font-size: 12px;
          font-weight: 600;
        }
      }

      .span_two {
        background: #ffcaca;
        display: flex;
        align-items: center;
        padding: 6px 20px;
        border-radius: 11px;

        p {
          font-size: 12px;
          font-weight: 600;
          color: #2a2a2b;
        }
      }
    }
  }

  .box__two__stepper {
    background-color: #3b3b3c;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem !important;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__one__left {
      display: flex;
      align-items: center;
      column-gap: 6px;

      h3 {
        background-color: #cad6ff;
        padding: 3px 11px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        color: #2a2a2b;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .box__one__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 17%;

      @media screen and (max-width: 540px) {
        width: 24%;
      }

      .span_two {
        background: #ffcaca;
        display: flex;
        align-items: center;
        padding: 6px 20px;
        border-radius: 11px;

        p {
          font-size: 12px;
          font-weight: 600;
          color: #2a2a2b;
        }
      }
    }
  }

  .box__three__stepper {
    background-color: #3b3b3c;
    width: 100%;
    margin-top: 1rem !important;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    column-gap: 6px;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    img {
      border-radius: 9px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .choice {
    text-align: center;
    color: white;
    padding-top: 1rem;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 0.3rem;
    position: relative;

    .i {
      position: absolute;
      top: 11px;
    }
  }

  .box__last {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;
    align-items: center;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .box__last__left {
      display: flex;
      align-items: center;
      column-gap: 6px;

      h3 {
        background-color: #2a2a2b;
        padding: 3px 11px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        color: white;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .box__last__right {
      color: #ffcaca;

      .span_one {
        p {
          background-color: #baeac8;
          font-size: 12px;
          font-weight: 600;
          padding: 5px 20px;
          border-radius: 6px;
          color: #2a2a2b;
        }
      }
    }
  }

  .select-container {
    position: relative;
    width: 100%;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .ant-select-selector {
      padding: 6px 30px 6px 40px;
      padding-right: 30px;
      padding-left: 40px;
      background-color: $dark-gray !important;
      outline: none !important;
      border: none !important;
      color: $text-white !important;

      .ant-select-selection-placeholder {
        color: white !important;
        opacity: 50% !important;
        padding: 1px 46px !important;
        @media screen and (max-width: 540px) {
          padding: 1px 45px;
        }
      }

      .ant-select-selection-overflow {
        row-gap: 8px;

        .ant-select-selection-overflow-item {
          background-color: transparent;

          .ant-select-selection-item {
            background-color: $bg-color !important;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .ant-select-selection-item-remove {
        color: white !important;
      }
    }

    .custom-suffix {
      position: absolute;
      top: 7px;
      left: 12px;

      @media screen and (max-width: 540px) {
        left: 13px;
      }
    }
  }

  .share_container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    padding: 0rem 2rem;
    gap: 1em;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 540px) {
      width: 90%;
      padding: 0rem 1rem;
    }

    .width_50 {
      width: 50%;
    }
  }

  .bottom {
    width: 100%;
    margin-top: 1rem !important;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .terminate {
      background-color: #caffd9;
      padding: 12px 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-radius: 10px;
      margin-bottom: 0.7rem;
      color: #2a2a2b;
      width: 100%;

      @media screen and (max-width: 540px) {
        font-size: 14px;
      }
    }

    .cancel {
      background-color: #ffcaca;
      padding: 7px 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      border-radius: 10px;
      color: #2a2a2b;
      width: 100%;

      @media screen and (max-width: 540px) {
        padding: 12px 10px;
        font-size: 14px;
      }
    }
  }
}

.tag_label {
  color: white !important;
  margin-top: 1rem !important;
  text-align: center !important;
}

.text_white {
  color: white;
}

.width_90_mx_auto {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.ant-select-dropdown {
  background-color: $dark-gray;

  .rc-virtual-list-holder-inner {
    row-gap: 4px;
  }

  .ant-select-item {
    color: $text-white !important;
  }

  .ant-select-item-option-selected {
    background-color: $bg-color !important;
    .ant-select-item-option-state{
      color: $text-white !important;
    }
  }

  .ant-empty-description{
    color: $text-white;
  }
}