@import "../../../general__style/var";

.read_more_modal {
  display: flex;
  flex-direction: column;

  .comments {
    margin-bottom: 2rem;
    max-height: 35vh;
    overflow-y: auto;

    @media screen and (max-width: 540px) {
      max-height: 40vh;
    }

    .comment_container {
      display: flex;
      text-align: left;
      column-gap: 5px;
      position: relative;
      align-items: center;
      margin-bottom: 3rem;

      .gap {
        gap: .8rem
      }

      img {
        max-width: 36px;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: contain;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  .links_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    gap: .8rem;

    .link_and_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $active-buttons-bg;
      font-size: 14px;
      font-weight: 700;
      gap: 2px;
      cursor: pointer;
      max-width: 140px;

      svg {
        max-width: 20px;
        max-height: 20px;

        path {
          stroke: currentColor;
        }
      }
    }
  }

  .msgdots {
    margin-left: auto;
    margin-right: auto;
    max-width: 24px;
    max-height: 24px;
  }

  #modal-modal-title-read-more {
    font-size: 14px !important;
    padding: 1rem 1rem;
    text-align: center;
    margin-top: 12px;
    word-wrap: break-word;
    white-space: pre-wrap;
    color: $text-white;
    @media screen and (max-width: 540px) {
      padding: .8rem .8rem;
      margin-top: 8px;
    }
  }

  #modal-modal-description {
    .input__groups__outcome {
      background-color: $dark-gray;
      border-radius: 10px;

      textarea {
        background-color: $dark-gray;
      }
    }
  }
}