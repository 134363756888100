@import "../../general__style/var";

.modal__title {
  font-size: 24px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}


.input__box {
  background-color: $bg-color;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .input__groups__wallet {
    background-color: $bg-color;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    border-radius: 15px;
    padding: 10px 12px;
    color: white;
    border: 1px solid grey;
    cursor: pointer;
  }
}

.wallet__popup {
  background-color: #3b3b3c !important;
  border: none !important;
  border-radius: 10px !important;
  text-align: center;
  position: relative;
  .date__amt {
    background-color: $bg-color !important;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    color: white;
  }
  
  .date__amt__head {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    span {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .already {
    font-size: 15px;
    color: white;
    font-weight: 500;
    margin-top: 1rem;
    a {
      color: #ffcaca;
    }
  }
}


.popups__btn {
  background-color: $active-buttons-bg;
  border: none;
  padding: 15px 20px;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  color: $active-text-color;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
}
