@import '../../general__style/var';

.notification {
  .notifications__content {
    width: 40%;
    padding-top: 3rem;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .noti__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      .noti__head__left {
        img {
          width: 30px;

          cursor: pointer;

          @media screen and (max-width: 540px) {
            width: 30px;
          }
        }
      }

      .noti__head__center {
        font-size: 26px;
        font-weight: 600;
        color: #ffff;
        margin-bottom: 9px;

        @media screen and (max-width: 540px) {
          display: none;
        }
      }

      .noti__head__right {
        img {
          cursor: pointer;
          width: 30px;

          @media screen and (max-width: 540px) {
            width: 30px;
          }
        }
      }
    }

    .noti__divs {
      margin-bottom: 20px;

      .noti__div_container {
        p {
          color: #ffffff;
        }

        .box {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: $dark-gray;
          padding: 8px 10px;
          border-radius: 10px;

          img {
            width: 46px;
            border-radius: 6px;
          }

          span {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.notification__settings {
  .notifications__content {
    width: 40%;
    padding-top: 3rem;
    margin: auto;

    @media screen and (max-width: 540px) {
      width: 90%;
    }

    .noti__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      .noti__head__left {
        img {
          cursor: pointer;
          width: 35px;
        }
      }

      .noti__head__center {
        font-size: 26px;
        font-weight: 600;
        color: #ffff;
        margin-bottom: 9px;
      }

      .noti__head__right {
        img {
          width: 35px;
        }
      }
    }

    .platform_list_container {
      margin: 1rem .5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;

      .switch_label_container {
        max-width: 60px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: .4rem;
      }
    }

    .noti__divs {
      margin-bottom: 20px;

      .section_title {
        color: $text-white;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .noti__div_container {
        p {
          color: #ffffff;
        }

        .box {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: $dark-gray;
          padding: 3px 10px;
          border-radius: 10px;
          margin-bottom: 4px;
          justify-content: space-between;

          img {
            width: 46px;
            border-radius: 12px;
          }

          span {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
          }

          .title_extra {
            font-size: 10px;
          }
        }
      }
    }
  }
}