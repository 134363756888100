@import "../../general__style/var";

.admin_page {
  display: flex;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .admin__left {
    width: 100%;

    .content {
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 540px) {
        width: 95%;
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        padding: 0 5px;
        font-size: 12px;
        font-weight: 500;

        @media screen and (max-width: 540px) {
          font-size: 12px;
        }

        p {
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
        }
      }

      label {
        color: white;
      }

      input {
        background-color: $dark-gray;
        padding: 15px;
        border: none;
        border-radius: 10px;
        color: white;

        &:focus {
          outline: none;
        }

        ::placeholder {
          color: rgba(255, 255, 255, 0.7);
        }

        @media screen and (max-width: 540px) {
          padding: 10px 15px;
        }
      }

      .action_buttons {
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem !important;
        padding: 1rem;

        @media screen and (max-width: 540px) {
          gap: .25rem
        }

        .button_container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          color: white;
          background-color: $dark-gray;
          padding: 2.5px 0px;

          p {
            font-size: 14px;
          }

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: white;
            }
          }

          .small_height {
            svg {
              height: 20px;
            }
          }

          @media screen and (max-width: 540px) {
            p {
              font-size: 10px;
            }

            svg {
              width: 20px;
              height: 20px;
            }

            .small_height {
              svg {
                height: 16px;
              }
            }
          }
        }

        .active {
          color: $active-buttons-bg;
          background: $light-gray;

          svg {
            path {
              fill: $active-buttons-bg;
            }
          }
        }
      }
    }
  }

  .admin__right {
    width: 100%;

    .center__content {
      background-color: transparent;
      display: flex;
      width: 80%;
      margin-bottom: 1rem !important;
      margin: auto;
      padding: 8px 10px;
      border-radius: 12px;
      border: 1px solid $dark-gray;

      @media screen and (max-width: 540px) {
        padding: 11px 10px;
        align-items: end;
      }

      img {
        cursor: pointer;
      }

      input {
        background: none;
        border: none;
        padding-left: 10px;
        color: $text-white;

        &:focus {
          outline: none;
        }
      }

      @media screen and (max-width: 600px) {
        width: 93%;
      }
    }

    .admin_user_container {
      padding-left: 1rem;
      padding-right: 1rem;
      width: 80%;
      margin: auto;

      @media screen and (max-width: 540px) {
        width: 93%;
      }

      .box__one {
        background-color: $dark-gray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 11px;
        margin-bottom: 1rem;
        padding: 7px 10px;

        @media screen and (max-width: 540px) {
          margin-bottom: .25rem;
          padding: 3px 8px;

        }

        .box__one__left {
          display: flex;
          align-items: center;
          column-gap: 6px;
          position: relative;
          width: 56%;

          .img {
            width: 36px;
            height: 36px;
            object-fit: cover;
            border-radius: 100%;
            font-size: 5px;
          }

          .category_img {
            border-radius: 30%;
          }

          .event_img {
            width: 52px;
            height: 28px;
            border-radius: 8px;
          }

          .text_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 40px);

            .amount_container {
              display: flex;
              align-items: center;
              gap: .25rem;
              font-size: 9px;
              color: white;
              opacity: 80%;
              font-weight: 500;

              svg {
                width: 12px;
                height: 12px;

                path {
                  fill: currentColor
                }

                ;
              }
            }
          }

          h3 {
            background-color: #cad6ff;
            padding: 3px 11px;
            border-radius: 9px;
            font-size: 14px;
            font-weight: 600;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          @media screen and (max-width: 540px) {
            .img {
              width: 28px;
              height: 28px;
            }
          }
        }

        .box__one__right {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: .5rem;
          align-items: center;
          justify-items: center;
          width: 44%;

          .checkbox_container {
            display: flex;
            align-items: center;
            width: fit-content;
            background-color: $light-gray;
            padding: 4px;
            border-radius: 4px;
          }
        }

        .single_child {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          justify-items: end;
          align-items: center;
        }
      }

      .header_tabs_container {
        display: flex;
        justify-content: end;
        padding: 0px 10px;
        margin-bottom: 1rem;

        @media screen and (max-width: 540px) {
          padding: 0px 8px;
          margin-bottom: .25rem;
        }

        .header_tabs {
          display: flex;
          justify-content: space-around;
          width: 44%;
          gap: 4px;

          @media screen and (max-width: 540px) {
            width: 44%;
          }
        }
      }

      .add_category_container {
        margin: 1rem 0rem;

        .input_container {
          background-color: $dark-gray;
          display: flex;
          position: relative;
          width: 100%;
          padding: 8px;
          border-radius: 10px;

          .category_input {
            color: white;
            margin-left: 32px;
            width: 100%;
            background-color: transparent;
            appearance: none;
            border: none;
            outline: none;
            font-size: 16px;
          }

          .file_input_container {
            display: flex;
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 4px;
            bottom: 0px;
            width: 28px;

            #fileInput {
              font-size: 0;
            }

            input.file_input {
              background: #3b3b3c;
              display: none;
              border: none;
              height: 40px;
              padding-left: 1rem;
              border-radius: 10px;
              color: white;
              width: 100%;
            }

            .img_gal {
              width: 100%;
              object-fit: contain;
              margin: auto;
            }
          }
        }

        .selected_file_name {
          font-size: 12px;
          color: white;
          padding-left: 8px;
          opacity: 70%;
        }
      }
    }
  }
}