@import "../../general__style/var";

.profile__stats {
  display: flex;
  width: 100%;
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .profile__left__stats {
    width: 100%;
    .profile__left__content {
      width: 100%;
      text-align: center;
      margin: auto;
      padding-top: 3.5rem;
      position: relative;
      @media screen and (max-width: 540px) {
        width: 90%;
        padding-top: 0;
      }
      .backIcon {
        position: absolute;
        left: 92px;
        width: 25px;
        cursor: pointer;
        @media screen and (max-width: 540px) {
          left: 15px;
        }
      }
      .profile__img {
        margin-top: 2rem;
        position: relative;
        img {
          width: 20%;
          margin-bottom: 1rem;
        }
        .verified_tag {
          position: absolute;
          top: 4.3rem;
          right: 200px;
          width: 20px;
          @media screen and (max-width: 540px) {
            position: absolute;
            top: 3.3rem;
            right: 142px;
            width: 20px;
          }
        }
      }
      .leaderboards__tabs_stats {
        background-color: $input-bg;
        width: fit-content;
        padding: 6px 7px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: 14px !important;
        margin: auto;
        .box__tabs {
          display: flex;
          column-gap: 1.5rem;
          a {
            text-decoration: none;
            color: $text-white;
            padding: 3px 5px;
          }
          a.active {
            background-color: #baeac8;
            border-radius: 6px;
            color: black;
            font-weight: bold;
            cursor: pointer;
          }
        }
        @media screen and (max-width: 600px) {
          padding: 6px 6px;
        }
      }
      .description {
        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.3);
          width: 80%;
          margin: auto;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
        span {
          display: flex;
          text-align: center;
          justify-content: center;
          width: 45%;
          margin: auto;
          padding: 8px 0;
          gap: 1rem;
          border-radius: 10px;
          border: 3px solid rgba(0, 0, 0, 0.25);
          background-color: #cae9ff;
          h6 {
            font-size: 16px;
            font-weight: 700;
            color: rgba(42, 42, 43, 1);
          }
        }
      }
      .buttons__two {
        display: flex;
        width: 63%;
        margin: auto;
        gap: 1px;
        @media screen and (max-width: 540px) {
          width: 90%;
        }
        .add_friend {
          width: 60%;
          display: flex;
          align-items: center;
          gap: 1.4rem;
          padding-left: 30px;
          font-size: 14px;
          padding-top: 11px;
          cursor: pointer;
        }
        .links {
          width: 40%;
          display: flex;
          align-items: center;
          gap: 0.4rem;
          padding-left: 30px;
          font-size: 14px;
          padding-top: 11px;
          cursor: pointer;
        }
      }
      .social__links {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: auto;
        gap: 0.7rem;
        padding-top: 1.5rem;
        span {
          background-color: #cae9ff;
          font-size: 14px;
          font-weight: 500;
          padding: 10px;
          border-radius: 10px;
        }
      }
      .chart_img {
        margin-top: 1rem !important;
        .apexcharts-toolbar {
          display: none !important;
        }
        .apexcharts-legend {
          display: none !important;
        }
      }
    }
  }
  .profile__right__stats {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: white;
    @media screen and (max-width: 540px) {
      margin-top: 4rem;
    }
    .four__badges {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 2rem;
      gap: 2rem;
      @media screen and (max-width: 540px) {
        display: flex;
        width: 90%;
        margin: auto;
        gap: 0;
      }
      .part1 {
        @media screen and (max-width: 540px) {
          display: flex;
        }
        .badge__one {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #3b3b3c;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 1rem !important;
          margin: auto;
          padding: 10px 0;
          .badges_img {
            width: 22px;
          }
          span {
            text-align: center;
            font-size: 12px;
          }
          @media screen and (max-width: 540px) {
            padding: 19px 10px;
            span {
              font-size: 8px;
            }
            .badges_img {
              width: 15px;
            }
          }
        }
        .badge__two {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #3b3b3c;
          text-align: center;
          padding: 19px 0;
          border-radius: 10px;
          width: 90%;
          margin-bottom: 1rem !important;
          margin: auto;
          .badges_img {
            width: 30px;
          }
          span {
            text-align: center;
          }
          @media screen and (max-width: 540px) {
            span {
              font-size: 8px;
            }
            .badges_img {
              width: 15px;
            }
          }
        }
      }
      .part2 {
        @media screen and (max-width: 540px) {
          display: flex;
        }
        .badge__three {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #3b3b3c;
          text-align: center;
          padding: 19px 0;
          border-radius: 10px;
          width: 90%;
          margin-bottom: 1rem !important;
          margin: auto;
          .badges_img {
            width: 30px;
          }
          span {
            text-align: center;
          }
          @media screen and (max-width: 540px) {
            span {
              font-size: 8px;
            }
            .badges_img {
              width: 15px;
            }
          }
        }
        .badge__four {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #3b3b3c;
          text-align: center;
          padding: 19px 0;
          border-radius: 10px;
          width: 90%;
          margin-bottom: 1rem !important;
          margin: auto;
          .badges_img {
            width: 30px;
          }
          span {
            text-align: center;
          }
          @media screen and (max-width: 540px) {
            span {
              font-size: 8px;
            }
            .badges_img {
              width: 15px;
            }
          }
        }
      }
    }

    .kyc_btn {
      display: flex;
      justify-content: center;
      .kyc__btn__img {
        width: 35%;
        cursor: pointer;
      }
    }

    .trust__lvls__h2 {
      margin-top: 2rem;
      @media screen and (max-width: 540px) {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }
    }

    .trust__lvl {
      display: flex;
      width: 80%;
      margin-top: 2rem !important;
      margin: auto;
      .trust__lvl__one {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #3b3b3c !important;
        text-align: center;
        padding: 19px 28px;
        border-radius: 10px;
        margin-bottom: 1rem !important;
        margin: auto;
        .trust__lvl_img {
          width: 30px;
        }
        span {
          text-align: center;
          font-size: 10px;
        }
        @media screen and (max-width: 540px) {
          padding: 19px 28px;
          span {
            font-size: 8px;
          }
          .trust__lvl_img {
            width: 30px;
          }
        }
      }
      .trust__lvl__two {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparent !important;
        text-align: center;
        padding: 19px 28px;
        border-radius: 10px;
        margin-bottom: 1rem !important;
        margin: auto;
        .trust__lvl_img_two {
          width: 24px;
        }
        span {
          text-align: center;
          font-size: 10px;
        }
        @media screen and (max-width: 540px) {
          padding: 19px 28px;
          span {
            font-size: 8px;
          }
          .trust__lvl_img_two {
            width: 24px;
          }
        }
      }
      .trust__lvl__three {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparent !important;
        text-align: center;
        padding: 19px 28px;
        border-radius: 10px;
        margin-bottom: 1rem !important;
        margin: auto;
        .trust__lvl_img_two {
          width: 24px;
        }
        span {
          text-align: center;
          font-size: 10px;
        }
        @media screen and (max-width: 540px) {
          padding: 19px 28px;
          span {
            font-size: 8px;
          }
          .trust__lvl_img_two {
            width: 24px;
          }
        }
      }
    }
  }
}
