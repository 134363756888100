@import '../../general__style/var.scss';

.event__boxes {
  width: 80%;
  margin: auto;
  padding: 2rem 0px 1.5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;

  @media screen and (max-width: 720px) {
    width: 80%;
    margin-top: 1rem;
  }

  .more__event__box {
    cursor: pointer;
    background-color: $dark-gray;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 10px;
    color: $text-white;
    font-size: 14px;
    text-align: left;
  }

  .white__background {
    background-color: $text-white;
  }

}

.more__btn_event {
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 22px;
  cursor: pointer;
  text-decoration: none;

  span {
    color: $text-white;
    font-size: 16px;
    font-family: "Inter" sans-serif;
  }
}