@import '../../../general__style/var';

.box__one__friends {
  background-color: $dark-gray;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem !important;
  margin: auto;
  padding: 7px 10px;
  border-radius: 11px;
  color: $text-white;

  @media screen and (max-width: 540px) {
    width: 93%;
  }

  .box__one__left {
    display: flex;
    align-items: center;
    column-gap: 6px;

    h3 {
      padding: 3px 11px;
      border-radius: 9px;
      font-size: 14px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      font-weight: 600;
    }

    img {
      width: 3rem;
      border-radius: 50%;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  .box__one__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .span_one {
      display: flex;
      align-items: center;
      color: white;
      padding: 6px 17px;
      border-radius: 11px;
      gap: 4px;

      p {
        font-size: 12px;
        font-weight: 700;
      }
    }

    .span_two {
      display: flex;
      align-items: center;
      padding: 6px 20px;
      border-radius: 11px;

      p {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

.following_img {
  @media screen and (max-width: 540px) {
    margin-left: 14px !important;
  }
}