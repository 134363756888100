@import "../../general__style/var";

.form__event_1-2 {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 3rem !important;
  margin-top: 2.5rem !important;
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 100%;
    padding: 0 1rem;
  }

  .title {
    color: $text-white;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
  }

  .link_container {
    width: 150px;
    align-self: center;
    padding-bottom: 2rem;
  }

  .label_with_icon {
    display: flex;
    align-items: center;
  }

  .optional_absolute {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  .input_group_name {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem !important;
    margin: auto;
    position: relative;

    label {
      color: white;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      padding-left: 10px;
      margin-bottom: 0.4rem;
    }

    input {
      background-color: $dark-gray;
      height: 40px;
      border: none;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;

      ::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }

    select {
      background-color: $dark-gray;
      height: 40px;
      border: none;
      color: #ffffff;
      padding: 0 1rem;
      border-radius: 10px;
      position: relative;

      :focus {
        outline: none !important;
      }

      :after {
        content: "";
        background-color: white;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: black transparent transparent transparent;
        position: absolute;
        top: 9px;
        right: 6px;
      }
    }
  }

  .input_group_desc {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem !important;
    margin: auto;

    label {
      color: white;
      font-size: 14px;
      font-weight: 500;
      padding-left: 10px;
      margin-bottom: 0.4rem;
      text-align: left;
    }

    textarea {
      background: $dark-gray;
      border: none;
      height: 83px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;
      padding-top: 7px;

      ::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }

      &:focus {
        outline: none;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }
  }

  .input_group_link {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    position: relative;

    label {
      color: white;
      font-size: 14px;
      font-weight: 500;
      padding-left: 10px;
      margin-bottom: 0.4rem;
      text-align: left;
    }

    .optional {
      position: absolute;
      right: 12px;
      font-size: 13px;
      top: 5px;
      color: rgba(255, 255, 255, 0.5);
    }

    input {
      background: $dark-gray;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;

      ::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }

      &:focus {
        outline: none;
      }
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }
  }

  .input__groups {
    margin-bottom: .5rem;
    position: relative;
  }

  .file_input {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;

    #fileInput {
      font-size: 0;
    }

    input {
      background: $dark-gray;
      display: none;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;
      width: 100%;
    }

    label {
      display: flex;
      padding-left: 50px !important;
      align-items: center;
      background-color: $dark-gray;
      border: none;
      height: 40px;
      padding-left: 1rem;
      border-radius: 10px;
      color: white;
      width: 100%;
      cursor: pointer;
    }

    .img_gal {
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 9px;
      width: 28px;
    }

    .optional {
      position: absolute;
      right: 10px;
      top: -18px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }

    .error_email {
      color: red;
      font-size: 12px;
      padding-left: 10px;
    }
  }

  // }

  .public__private {
    background-color: $dark-gray;
    padding: 6px 0;
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .box__tabs {
      display: flex;
      column-gap: 2rem;
      width: 95%;
      margin: auto;

      a {
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: $text-white;
        padding: 3px 5px;
      }

      a.active {
        background-color: #baeac8;
        border-radius: 6px;
        color: black;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .step_one_label_optional {
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    text-align: right;
    padding-left: 10px;
    padding-right: 10px;
  }

  .fees_input_container {
    color: $text-white;
    background-color: $dark-gray;
    width: 100%;
    display: flex;
    border-radius: 10px;
    overflow-y: hidden;

    input {
      color: $text-white;
      padding-left: 8px;
      background-color: transparent;
      border: none;
      outline: none;
      &:disabled {
        cursor: not-allowed;
      }
    }

    .counter_arrow_container {
      width: 36px;
      background-color: rgba(255, 255, 255, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      .counter_arrow_img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        cursor: pointer;
      }

      .rotate_up {
        transform: rotate(180deg);
      }
    }

    .total_fees_container {
      margin: 4px 6px;
      padding: 2px 8px;
      color: $dark-gray;
      background-color: $active-buttons-bg;
      border-radius: 10px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      font-weight: 600;
    }

    .fees_input {
      flex-grow: 1;
      width: 100%;
    }

    .hr_separator {
      border: none;
      border-left: 2px solid rgba(255, 255, 255, 0.5);
      margin: 4px 10px
    }
  }

  .fees_info {
    font-size: 10px;
    display: flex;
    margin-left: auto;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    margin: 4px 10px 0px 0px;
    flex-shrink: 0;
    margin-bottom: .5rem;
  }

  .final__step {
    background-color: #caffd9;
    padding: 10px 20px;
  }
}