@import "../../../../general__style/var";

.challenge_container {
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 10px;
    column-gap: 4px;
    min-height: 40px;
    background-color: $dark-gray;
}

.event_container {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    padding: 4px 0px;
    font-size: 14px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    column-gap: 4px;
    // background-color: $dark-gray;
}

.event_container,
.challenge_container {

    .challenge_serial {
        color: $text-white;
        background-color: $light-gray;
        font-size: 14px;
        border-radius: 10px;
        height: 28px;
        width: 28px;
        min-width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .min_w_4rem {
        min-width: 4.0625rem;

        @media screen and (max-width: 403px) {
            min-width: auto;
        }
    }

    .icon_title_container {
        gap: 4px;
        width: calc(100% - 9rem);

        @media screen and (max-width: 403px) {
            width: calc(100% - 7rem);
        }
    }

    .badge_container {
        display: flex;
        align-items: center;
        column-gap: 4px;
    }

    .title_container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.38;
            color: $text-white;
        }

        .currency_container {
            display: flex;
            flex-direction: row;
            column-gap: 2px;
            line-height: normal;
            color: $text-white;
            opacity: 60%;

            .currency_icon {
                font-size: 7px;
                svg {
                    width: 10px;
                    height: 8px;

                    path {
                        fill: rgba(255, 255, 255, 0.6);
                    }
                }
            }

            .currency {
                font-size: 7px;
            }

            .refund_badge {
                display: flex;
                align-items: center;
                width: fit-content;
                font-size: 7px;
                color: $active-buttons-bg-pink;
                background-color: $dark-pink;
                padding: 0px 4px;
                border-radius: 4px;
                line-height: 1;
            }
        }
    }
}