@import "../../../general__style/var";

.review_claims {
  color: $text-white;

  .table_container {
    max-height: 50vh;
    overflow-y: auto;
    position: relative;
    padding-right: 2px;

    .table_fixed {
      table-layout: fixed;
      width: 100%;
      border-spacing: 0.25rem 0.25rem;
      border-collapse: collapse;

      .table_row {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 14px;
      }

      .table_header {
        font-size: 12px;
        opacity: 70%;
        position: sticky;
        top: -1px;
        left: -2px;
        opacity: 100%;
      }
    }
  }

  .padding_top_bottom {
    padding-top: .5rem;
    padding-bottom: .2rem;
  }

  .text_left {
    text-align: left;
  }

  .text_right {
    text-align: right;
  }

  .w_7rem {
    min-width: 4.0625rem;

    @media screen and (max-width: 540px) {
      min-width: auto;
    }
  }

  .challenge_serial {
    color: black;
    background-color: $active-buttons-bg;
    font-size: 14px;
    border-radius: 10px;
    height: 28px;
    width: 28px;
    min-width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .event_image {
    display: flex;
    align-items: center;
    img {
      width: 38px;
      height: 20px;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .dark {
    width: fit-content;
    background-color: #baeac8;
    padding: 3px 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3px;
    font-size: 22px;
    color: #3b3b3c;
  }

  .lossStatus {
    background-color: $active-buttons-bg-pink;
  }

  .action_container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 1rem;
  }

  .feedback_container {
    margin: 1rem 0;
    position: relative;
    text-align: left;

    .optional_text {
      color: white;
      position: absolute;
      right: 10px;
      top: 0px;
      opacity: 70%;
      z-index: 10;
      font-size: 12px;
    }

    .text_area_container {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 34px;
      position: relative;
      border-radius: 10px;
      background-color: black;

      .right_absolute {
        position: absolute;
        right: 6px;

        .file_input {
          position: relative;
        }

        input {
          background: #3b3b3c;
          opacity: 0;
          border: none;
          height: 40px;
          padding-left: 1rem;
          border-radius: 10px;
          color: white;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }

        img {
          cursor: pointer;
        }
      }

      .feedback_textarea {
        color: white;
        width: 100%;
        height: 100%;
        background-color: black;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .link_input_container {
      width: 100%;

      .link_input {
        width: 100%;
        background-color: $bg-color;
        border: none;
        outline: none;
        padding: 14px;
        border-radius: 10px;
        color: white;
      }
    }

    .label {
      font-size: 14px;
    }
  }
}

.review {
  .review_info {
    margin: 1rem auto;
    color: #FFFFFF;
    font-size: 16px;
    max-width: 80%;
    font: 600 12px/calc(14/12) poppins;
  }

  .review_info_with_icon {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #FFCACA;
  }
}

.icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  .tick {
    background-color: #caffd9;
    font-size: 40px;
    width: 52px;
    border-radius: 10px;
    color: #2b3f6c;
  }

  .close {
    background-color: #ffcaca;
    font-size: 40px;
    width: 52px;
    border-radius: 10px;
    color: #2b3f6c;
  }
}

#modal-modal-title {
  color: $text-white !important;

  @media screen and (max-width: 540px) {
    font-size: 1rem !important;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 1.5rem !important;
  }
}


.table_row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.claims_container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 6px;
}