.all__pups {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 0 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 1rem;
  }
  .all__btn {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid white;
    cursor: pointer;
  }
}
