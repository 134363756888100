@import '../../general__style/var.scss';

.wallet__deposit {
  h1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .wallet__content {
    display: flex;

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }

    .wallet__left {
      width: 100%;
      position: relative;

      .winning_reward_container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        row-gap: 1.5rem;
        width: 80%;
        margin: auto;

        @media screen and (max-width: 540px) {
          width: 90%;
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .single_container {
          display: flex;
          flex-direction: column;

          .title {
            font-size: 12px;
            color: $text-white;
            font-weight: 500;
            opacity: 80%;
            margin-left: 8px;
          }

          .box_container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: 600;
            color: $active-buttons-bg-dark;
            background-color: $dark-gray;
            border-radius: 10px;
            position: relative;
            min-height: 72px;

            .makao_svg {
              position: absolute;
              top: 12px;
              right: 12px;
            }

            svg {
              max-width: 20px;
              max-height: 20px;

              path {
                fill: currentColor
              }
            }
          }

          .button_container {
            margin: -12px 12px 0px 12px;
            z-index: 1;
          }
        }

        .send_buttons {
          gap: .4rem;
          margin: auto 0px;

          @media screen and (max-width: 540px) {
            flex-direction: row;
            margin: auto 12px;
          }
        }
      }

      .inDapp {
        color: rgba(255, 255, 255, 0.63);
        font-size: 14px;
        font-weight: 500;
        padding-left: 60px;

        @media screen and (max-width: 540px) {
          padding-left: 33px;
        }
      }

      .wallet__left__top__box {
        position: relative;

        @media screen and (max-width: 540px) {
          z-index: -1;
        }

        .top__box {
          display: flex;
          align-items: center;
          background-color: #3b3b3c;
          width: 80%;
          margin: auto;
          padding: 24px 12px;
          border-radius: 18px;

          @media screen and (max-width: 540px) {
            width: 90%;
          }

          span {
            font-size: 20px;
            color: #ffffff;

            @media screen and (max-width: 540px) {
              font-size: 18px;
            }
          }

          .numbers {
            color: #baeac8;
            font-size: 30px;
            font-weight: 500;
            padding-left: 20px;
            padding-right: 5px;
          }

          h3 {
            color: #baeac8;
            padding-bottom: 10px;
            font-weight: 400;
          }
        }

        .i {
          position: absolute;
          top: 11px;
          right: 65px;
          width: 18px;

          @media screen and (max-width: 540px) {
            top: 6px;
            right: 25px;
          }
        }
      }

      .view {
        width: 30%;
        padding-top: 4px;
        margin: auto;

        @media screen and (max-width: 540px) {
          width: 40%;
          margin-top: -1rem;
        }
      }

      .ddeposit__four__butons {
        width: 60%;
        margin: 1rem auto;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;

        @media screen and (max-width: 540px) {
          width: 80%;
        }
      }

      .down__box {
        background-color: #3b3b3c;
        text-align: center;
        padding: 2rem 0;
        border-radius: 18px;
        width: 80%;
        margin-top: 2rem !important;
        margin: auto;

        @media screen and (max-width: 540px) {
          margin-bottom: 2rem;
        }

        h2 {
          font-size: 20px;
          font-weight: 700;
        }

        .stacking__balance {
          .stacking {
            display: flex;
            background-color: $bg-color;
            width: 70%;
            margin: auto;
            justify-content: space-between;
            padding: 10px 27px;
            border-radius: 10px;

            @media screen and (max-width: 540px) {
              width: 90%;
            }
          }

          .unlocked {
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
            padding-right: 8rem;
            color: rgba(255, 255, 255, 0.8);
          }
        }

        .your__bet {
          .Your__bet__text {
            color: #ffffff;
            text-align: left;
            padding-right: 230px;
          }

          .stacking__balance__two {
            .stacking {
              display: flex;
              background-color: $bg-color;
              width: 80%;
              margin: auto;
              justify-content: space-between;
              padding: 10px 27px;
              border-radius: 10px;

              @media screen and (max-width: 540px) {
                width: 95%;
              }
            }
          }
        }

        .no__extra__fee {
          display: flex;
          justify-content: space-between;
          width: 80%;
          margin-top: 1rem !important;
          margin-bottom: 0.5rem !important;
          margin: auto;

          span {
            font-size: 16px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
          }
        }

        .view__stake {
          width: 80%;
          margin: auto;
          font-size: 14px;
          font-weight: 500;
          color: #2a2a2b;
        }
      }
    }

    .wallet__right {
      width: 100%;

      @media screen and (max-width: 540px) {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      .top__boxes__head {
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        margin-bottom: -2px;

        @media screen and (max-width: 540px) {
          width: 90%;
        }

        span {
          font-size: 15px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.63);
          position: relative;

          img {
            position: absolute;
            right: -15px;
            top: -3px;
          }
        }
      }

      .top__box {
        background-color: $dark-gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        padding: 16px;
        color: white;
        border-radius: 10px;

        @media screen and (max-width: 540px) {
          width: 90%;
        }

        .top__box__left {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            width: 35px;
          }

          span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .top__box__right {
          display: flex;
          flex-direction: column;
          gap: 6px;

          span {
            font-size: 15px;
            font-weight: 500;
            color: #cae9ff;
            text-align: right;
          }
        }
      }

      .bottom__boxes {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-top: 2rem;
        max-height: 60vh;
        overflow-y: auto;

        .bottom__boxes__box {
          background-color: $dark-gray;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 80%;
          margin: auto;
          padding: 8px 16px;
          color: white;
          border-radius: 10px;
          cursor: pointer;

          @media screen and (max-width: 540px) {
            width: 90%;
          }

          .top__box__left {
            display: flex;
            align-items: center;
            gap: 6px;

            img {
              width: 24px;
            }

            span {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .top__box__right {
            display: flex;
            flex-direction: column;
            gap: 6px;

            span {
              font-size: 15px;
              font-weight: 500;
              color: #baeac8;
            }
          }
        }

        .bottom__boxes__head {
          width: 80%;
          margin: auto;
          display: flex;
          justify-content: space-between;
          padding: 0 16px;
          margin-bottom: -7px;

          @media screen and (max-width: 540px) {
            width: 90%;
          }

          span {
            font-size: 15px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.63);
          }
        }
      }

      .view {
        padding-top: 1.5rem;
        width: 30%;
        margin: auto;
      }
    }
  }

  .dap_balance {
    background-color: $dark-gray;
    width: 80%;
    margin: auto;
    padding: 24px 12px;
    border-radius: 18px;
    margin-top: 1rem;
    color: $text-white;

    .address {
      max-width: 100%;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}