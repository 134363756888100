.more__btn_scan {
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
  text-decoration: none;
  span {
    color: white;
    font-size: 16px;
    font-family: "Inter" sans-serif;
    @media screen and (max-width: 540px) {
      font-size: 13px;
    }
  }
  @media screen and (max-width: 540px) {
    margin-left: 1.5rem;
    span {
      img {
        width: 12px;
      }
    }
  }
}

.QR {
  width: 60%;
  padding-top: 4rem;
  margin: auto;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 540px) {
    padding-top: 7rem;
  }
  img {
    width: 50%;
    text-align: center;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
}
