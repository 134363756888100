@import "../../general__style/var";

.event__page {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    padding: 1rem .8rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    column-gap: .4rem !important;
    margin-top: 1rem;

    .left__button {
      display: flex;
      align-items: center;
      background-color: #3b3b3c;
      width: 36%;
      justify-content: center;
      border-radius: 12px;
      padding: 6px;
      position: relative;
      box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.25) inset;

      .title {
        font-size: 10px;
        font-weight: "600";
        line-height: unset
      }

      .username {
        font-size: 14px;
        font-weight: "600";
        display: 'inline-block';
        max-width: '100%';
        line-height: unset
      }

      .center {
        display: flex;
        flex-direction: column;
        padding: 0 38px 0 10px;
        position: relative;
        justify-content: center;
      }

      span {
        font-size: 12px;
        color: white;
      }

      @media screen and (max-width: 540px) {
        width: 50%;
        padding: 2px 6px;

        .title {
          line-height: 1
        }

        .username {
          line-height: 1.25
        }
      }

    }

    .bg_pink {
      background-color: $active-buttons-bg-pink;
    }

    .dark_cyan {
      background-color: $active-buttons-bg-dark
    }

    .right__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 36%;
      justify-content: center;
      border-radius: 12px;
      padding: 6px;
      position: relative;
      box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.25) inset;

      @media screen and (max-width: 540px) {
        width: 50%;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .buttons__two {
    margin-top: 6px !important;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 73%;

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

  .width_half {
    width: calc(50% - 6px);
  }

  .width_60 {
    width: calc(60% - 6px);

    img {
      max-width: 20px;
    }
  }

  .width_40 {
    width: calc(40% - 6px);

    img {
      max-width: 20px;
    }
  }

  .event_single_action_button {
    width: 100%;
    margin-top: 6px;
    padding-left: 8px;
    padding-right: 8px;
  }


  .action_buttons_container_event {
    width: 100%;
    margin-top: 1rem;
  }

  .event__main__box {
    width: 50%;

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

}

.event__main__box_second {
  width: 100%;
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 100%;
    margin-top: 1rem !important;
  }

  .box__event__page {
    counter-reset: challenge;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    h2 {
      color: white;
      padding-bottom: 1rem;
    }

    .box__head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: auto;

      @media screen and (max-width: 540px) {
        width: 100%;
      }

      .box__head__left__event__page {
        display: flex;
        padding-left: 1rem;
        font-size: 14px;
        font-weight: 400;
        gap: 1rem;
        color: rgba(255, 255, 255, 0.7);
      }

      .box__head__right__event__page {
        width: 38%;

        @media screen and (max-width: 520px) {
          width: 45%;
        }

        .span__Ps {
          display: flex;
          column-gap: 60px;
          padding-right: 10px;
          justify-content: space-between;

          @media screen and (max-width: 1280px) {
            column-gap: 20px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }

    .box__one__event__page {
      counter-increment: challenge;
      background-color: $dark-gray;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      margin: auto;
      padding: 7px 10px;
      border-radius: 11px;

      @media screen and (max-width: 540px) {
        width: 100%;
        padding: 4px 10px;
      }

      .box__one__left {
        display: flex;
        align-items: center;
        flex-grow: 1;
        column-gap: 6px;
        max-width: 62%;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;

        @media screen and (max-width: 540px) {
          width: 55%;
        }

        .logic_container {
          padding: 3px 8px;
          margin-left: 32px;
          max-width: calc(100% - 36px);
        }
      }

      .box__one__left::before {
        position: absolute;
        content: counter(challenge);
        width: 32px;
        background-color: $light-gray;
        color: $active-buttons-bg;
        border-radius: 10px;
        line-height: 1.9;
        text-align: center;
        font-weight: 600;
      }

      .box__one__right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 38%;

        @media screen and (max-width: 540px) {
          width: 45%;
        }

        .span_one {
          display: flex;
          align-items: center;
          background-color: #2a2a2b;
          color: white;
          padding: 6px 17px;
          border-radius: 11px;
          gap: 4px;

          p {
            font-size: 12px;
            font-weight: 600;
          }

          @media screen and (max-width: 540px) {
            padding: 4px 8px;
            gap: 2px;
          }

        }

        .span_two {
          color: white;
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 6px 20px;
          border-radius: 11px;

          p {
            font-size: 12px;
            font-weight: 600;
          }

          @media screen and (max-width: 540px) {
            padding: 4px 12px;
            gap: 2px;
          }
        }

        .odd_cyan_shadow {
          box-shadow: 0px 0px 4px 2px rgba(186, 234, 200, 1) inset;
        }

        .odd_pink_shadow {
          box-shadow: 0px 0px 4px 2px rgba(255, 202, 202, 1) inset;
        }

        .refund_button {
          background: #ffcaca;
          color: #2a2a2b;
        }

      }
    }

    .selected__challenge {
      box-shadow: 0 0 0.3rem white;
      box-sizing: border-box;

      @media screen and (max-width: 540px) {
        box-shadow: 0 0 0.3rem white;
      }

      span.span_one {
        background-color: #CAFFD9 !important;
      }
    }

    .box__two {
      background-color: #3b3b3c;
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: auto;
      padding: 7px 10px;
      border-radius: 11px;

      @media screen and (max-width: 540px) {
        width: 100%;
      }

      .box__one__left {
        display: flex;
        align-items: center;
        column-gap: 6px;

        h3 {
          background-color: #cad6ff;
          padding: 3px 11px;
          border-radius: 9px;
          font-size: 14px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
        }
      }

      .box__one__right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 35%;

        @media screen and (max-width: 540px) {
          width: 48%;
        }

        .span_one {
          display: flex;
          align-items: center;
          background-color: #FFCACA;
          color: white;
          padding: 6px 17px;
          border-radius: 11px;
          gap: 4px;

          p {
            font-size: 12px;
            font-weight: 600;
          }
        }

        .less_margin {
          padding: 4px 8px !important;
        }

        .span_two {
          background: #baeac8;
          display: flex;
          align-items: center;
          padding: 6px 20px;
          border-radius: 11px;

          p {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    .box__three {
      width: 100%;
      margin: auto;
      padding: 7px 10px;
      border-radius: 11px;
      display: flex;
      align-items: center;
      column-gap: 6px;
      margin-top: 1rem;

      @media screen and (max-width: 540px) {
        width: 100%;
      }

      .plus_icon {
        width: 32px;
        height: 28px;
        border-radius: 8px;
        color: $text-white;
        box-shadow: 0px 0px 4px 0px $text-white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: $text-white;
      }
    }
  }

  .choice {
    text-align: center;
    color: white;
    padding-top: 1rem;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 0.3rem;
  }

  .box__last {
    counter-increment: challenge;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: 7px 10px;
    border-radius: 11px;
    align-items: center;
    margin-bottom: .6rem;

    @media screen and (max-width: 540px) {
      width: 100%;
    }

    .box__last__left {
      display: flex;
      align-items: center;
      column-gap: 6px;
      font-size: 14px;
      font-weight: 600;
      color: white;

      h3 {
        font-size: 14px;
        background-color: transparent;
        padding: 3px 11px;
        margin-left: 32px;
      }
    }

    .box__last__left::before {
      position: absolute;
      content: counter(challenge);
      width: 32px;
      background-color: $light-gray;
      border-radius: 10px;
      line-height: 1.9;
      text-align: center;
      font-weight: 600;
    }

    .box__last__right {
      color: $active-buttons-bg-pink !important;


      .span_one {
        p {
          background-color: $dark-gray;
          font-size: 12px;
          font-weight: 600;
          padding: 5px 20px;
          border-radius: 6px;
          color: $active-buttons-bg-pink !important;
        }
      }

      .accept_button {
        p {
          background-color: #baeac8 !important;
          color: #2a2a2b !important;
        }
      }

    }
  }
}

.event_challenges_container {
  width: 50%;
  margin-left: 1rem;
  margin-right: 1rem;

  @media screen and (max-width: 540px) {
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}