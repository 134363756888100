@import '../../../general__style/var';

.box__head__players {
  display: flex;
  justify-content: center;
  width: 32%;
  gap: 10px;
  margin: auto;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 540px) {
    width: 50%;
  }
}

.box__one__players {
  color: $text-white;
  background-color: $dark-gray;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px !important;
  margin-top: 4px !important;
  margin: auto;
  padding: 2px 10px;
  border-radius: 10px;

  @media screen and (max-width: 540px) {
    width: 93%;
  }

  .box__one__left {
    display: flex;
    align-items: center;
    column-gap: 6px;

    p {
      font-size: 14px;
      font-weight: 600;
    }

    img {
      max-width: 36px;
      border-radius: 50%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  .box__one__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    gap: 1rem;

    @media screen and (max-width: 540px) {
      width: 48%;
    }

    .span_one {
      display: flex;
      align-items: center;
      padding: 6px 17px;
      border-radius: 11px;
      gap: 4px;

      p {
        font-size: 12px;
        font-weight: 700;
      }
    }

    .span_two {
      display: flex;
      align-items: center;
      padding: 6px 13px;
      border-radius: 11px;

      @media screen and (max-width: 540px) {
        padding: 6px 0px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}