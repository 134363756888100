// background-color
$bg-color: #0D0D0E;

// active-text-color
$active-text-color: #2a2a2b;

// active-bg
$active-buttons-bg: #cae9ff;

// active-bg-dark
$active-buttons-bg-dark: #baeac8;

// active-bg-pink
$active-buttons-bg-pink: #FFCACA;

$dark-pink: #362727;

$dark-green: #27362C;

$neon-green: #CAFFD9;

// white-text-color
$text-white: #ffffff;

// input-field-color
$input-bg: #3b3b3c;

// 
$gray: #363E45;

// light-gray
$light-gray: #272D36;

// 
$dark-gray: #121316;

// font-family
$font-family: "Poppins" sans-serif;