@import "../../general__style/var";

.welcome__screen {
  background-color: $bg-color;
  display: flex;
  width: 100%;
  margin: auto;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .welcome__screen__left {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    justify-content: center;
    align-items: center;
    align-content: center;

    @media screen and (max-width: 768px) {
      display: none;
      height: 100vh;
      min-height: 100vh;
      grid-template-rows: repeat(2, minmax(0, 1fr));
      align-items: center;
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-self: flex-end;
    }

    img {
      width: 6rem;
      margin: auto;
    }

    span {
      color: $text-white;
      padding-top: 3rem;
    }

    h1 {
      color: $active-buttons-bg;
      font-size: 50px;
      font-weight: 700;
    }

    .login__btn {
      width: 50%;
      background-color: #baeac8;
    }

    p {
      align-self: flex-end;
      color: white;
      padding-bottom: 4rem;
      text-align: center;
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  .seprate__line {
    height: 70%;
    margin: auto;
    border-color: rgba(255, 255, 255, 0.1) !important;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .welcome__screen__right {
    width: 100%;
    margin: auto;
    height: 100vh;
    min-height: 100vh;
    overflow-y: auto;

    .welcome__screen__right__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      position: relative;
      height: -webkit-fill-available;

      .loading {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.6);
        background-color: $bg-color;
        z-index: 100;
        font-weight: 600;
        font-size: 14px;
      }

      .warring {
        color: #E78B3A;
        font-size: 18px;
        margin-top: 14px;
      }

      .disclaimer {
        font-size: 10px;
        color: white;
        opacity: .8;
        max-width: 390px;
        text-align: center;
      }

      .footer__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
      }

      h3 {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 1rem;
        font-family: $font-family;
        color: $text-white;
      }

      .social__buttons {
        display: flex;
        flex-direction: column;
        width: 55%;
        margin: auto;
        row-gap: 0.5rem;
        padding-bottom: 0.5rem;
        padding-top: 1rem;

        button {
          display: flex;
          align-items: center;
          height: 38px;
          padding-left: 20px;
          column-gap: 15px;
          border-radius: 5px;
          border: none;
          background-color: #3b3b3c;
          color: $text-white;
          cursor: pointer;

          img {
            width: 25px;
          }
        }

        @media screen and (max-width: 550px) {
          width: 80%;
        }
      }

      .division {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.6rem;

        p {
          padding: 0 10px;
          color: $text-white;
        }

        .line {
          width: 20%;
          height: 1px;
          border-color: rgba(255, 255, 255, 0.1) !important;

          @media screen and (max-width: 550px) {
            width: 35%;
          }
        }
      }

      .signup__form {
        width: 55%;
        margin: auto;

        @media screen and (max-width: 550px) {
          width: 80%;
        }

        .input_group {
          label {
            color: $text-white;
            font-size: 13px;
            font-weight: 500;
            font-family: $font-family;
          }

          .ant-input::placeholder {
            color: $text-white;
          }

          .ant-input-affix-wrapper>input.ant-input {
            background-color: #3b3b3c;
            color: $text-white;
            height: 38px;
            border-radius: 5px;
          }

          .ant-input-affix-wrapper {
            border: none;
            background-color: #3b3b3c;
            padding: 0;
            column-gap: 5px;
          }

          .ant-input-prefix {
            padding-left: 10px;
          }
        }

        .input_group_two {
          margin-top: 16px !important;
          position: relative;

          .Optional {
            font-size: 13px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 17.5rem;

            @media screen and (max-width: 540px) {
              position: absolute;
              top: 4px;
              margin: 0;
              right: 6px;
            }
          }

          input::placeholder {
            opacity: 0.4;
          }

          /* Set the placeholder color and opacity for a specific input field with a class */

        }

        .input_group_three {
          position: relative;
        }

        .Optional {
          font-size: 13px;
          font-weight: 500;
          color: #ffffff;
          margin-left: 13.5rem;

          @media screen and (max-width: 540px) {
            position: absolute;
            top: 4px;
            margin: 0;
            right: 6px;
          }
        }

        .checkbox__text {
          color: $text-white;
        }

        .get__started {
          margin-top: 1rem;
          background-color: #baeac8;
        }

        .terms {
          color: $active-buttons-bg;
          padding-left: 4px;
          cursor: pointer;
        }

        .ant-checkbox {
          align-self: flex-start;
          margin-top: 5px;
        }

        .terms_and_condition {
          color: white;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }

  .order_1{
    order: 1;
  }
  .order_2{
    order: 2;
  }
}

.heartImg_signup {
  position: absolute;
  left: 44%;
  top: 37%;

  @media screen and (max-width: 540px) {
    left: 31%;
  }
}


#welcome__screen__right__w3a__container {
  min-height: 40vh;
}

.w3a-parent-container {
  z-index: 100 !important;
}

.w3a-parent-container #w3a-modal {
  position: relative !important;

  .w3a-button.w3a-button--primary {
    color: #3b3b3c !important;

    .image-icon {
      color: #3b3b3c !important;
      fill: #3b3b3c !important;
    }
  }

  button.w3a-button--login>.hover-icon {
    display: block !important;
  }

  button.w3a-button--login>.image-icon {
    display: none !important;
  }

  .w3a-header__button.w3ajs-close-btn {
    display: none !important;
  }

  .w3a-button-expand {
    display: none !important;
  }

  .w3a-modal__footer {
    display: none !important;
    padding: 0 !important;
  }

  .w3a-modal__inner {
    min-height: 570px !important;
  }
}