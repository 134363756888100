.screens {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 2rem;
  }
  a {
    width: 100%;
    padding: 10px 0;
  }
  button {
    background: transparent;
    border: 1px solid #3c3b3c;
    padding: 10px 0;
    color: white;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: #3c3b3c;
      color: white;
    }
  }
}
