@import "../../../general__style/var";

.possible_outcome_container {
  margin: 2rem 1rem 1rem 1rem;

  .input__groups__outcome {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;

    label {
      color: white;
    }

    input, textarea {
      background-color: $dark-gray;
      border: none;
      outline: none;
      padding: 14px;
      border-radius: 10px;
      color: white;

      ::placeholder {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}