@import "../../general__style/var";

.leaderBoards {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 540px) {
    margin-bottom: 2rem;
  }

  img {
    text-align: center;
    width: 23px;
  }

  .backIcon_leaderboards {
    width: 35px;
    margin-left: 1.7rem;
    cursor: pointer;

    @media screen and (max-width: 540px) {
      width: 28px;
    }
  }

  a {
    text-decoration: none;

    .status {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 15%;
      justify-content: center;
      padding-left: 10px;
      cursor: pointer;
      margin-bottom: 1rem !important;
      margin: auto;

      @media screen and (max-width: 540px) {
        width: 38%;
      }
    }
  }
}

.leaderboards__tabs {
  width: fit-content;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem !important;
  margin: auto;

  @media screen and (max-width: 540px) {
    margin-bottom: 1rem !important;
    width: 83%;
    padding: 6px 6px;
  }

  .box__tabs {
    display: flex;
    column-gap: 1.5rem;
    justify-content: space-between;

    @media screen and (max-width: 540px) {
      column-gap: .8rem;
    }

    a {
      text-decoration: none;
      color: $text-white;
      padding: 3px 10px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
    }

    a.active {
      background-color: $active-buttons-bg;
      border-radius: 6px;
      color: black;
      font-weight: bold;
      cursor: pointer;

      @media screen and (max-width: 540px) {
        padding: 3px 6px;
      }
    }
  }
}

.button__week {
  select {
    background-color: $dark-gray;
    width: 29%;
    color: $text-white;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 8px;
    border-radius: 4px;
    margin-bottom: 1rem !important;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.leaderBoards__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 0 2rem;
  color: white;

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 4px;
    margin-top: 1.5rem !important;
  }
}

.all__boxes {
  .box__down {
    display: flex;
    align-items: center;
    background-color: $dark-gray;
    padding: 2px 12px;
    border-radius: 10px;
    justify-content: space-between;
    gap: 1rem;

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;

      .leader {
        font-weight: 600;
        font-size: 20px;
        color: $text-white;
        position: relative;

        .leader_place {
          font-size: 10px;
          position: absolute;
          top: 2px;
          right: -12px;
        }
      }

      span {
        display: flex;
        align-items: center;
        column-gap: 10px;

        img {
          width: 36px;
          border-radius: 50%;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }
    }

    .right {
      font-size: 16px;
      font-weight: 500;
      color: $active-buttons-bg-dark;
      display: flex;
      gap: 4px;
    }
  }
}

.button_view {
  width: 20%;
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 50%;
  }

  .view__more {
    padding: 10px 20px;
  }
}