@import 'react-toastify/dist/ReactToastify.css';
@import 'react-phone-number-input/style.css';
@import './spinner.scss';
@import "./var";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

body {
  background-color: $bg-color;
}

.layout {
  display: flex;

  .sidebar {
    position: fixed;
    width: 20%;

    @media screen and (max-width: 540px) {
      position: absolute;
      left: 0;
      right: 0;
      top: -120%;
    }
  }

  .sidebar__mbl {
    position: absolute;
    left: -100%;
    z-index: 1000;
    transition: 0.5s ease-in-out;

    @media screen and (max-width: 540px) {
      left: 0;
      right: 0;
      top: 70px;
      transition: 0.5s ease-in-out;
    }
  }

  .dash {
    width: 80%;
    margin-left: auto;

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

  .dash__mbl {
    width: 100%;
    margin-top: 0.6rem;
  }

  .dash_100 {
    width: 100%;
  }
}

.navigation__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  a {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.popupBtn {
  display: flex;
  justify-content: center;
  border: 1px solid white;
  color: white;
  width: 15%;
  padding: 5px;
  margin-right: 1rem !important;
  border-radius: 10px;
  margin-left: auto;
  margin-bottom: 1rem;
  cursor: pointer;

  @media screen and (max-width: 540px) {
    width: 40%;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: white;
  }

  &:hover {
    background-color: #27272d;
  }
}

.popup_heading {
  font-weight: 700 !important;
}

.infoPopup_heading {
  font-weight: 600 !important;
}

a {
  line-height: 1.25;
}

.css-i3fw42-MuiTypography-root {
  margin-top: 2rem !important;
}

////////////////////////////////////////////////////////
///
///
.ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
  background-color: #cae9ff !important;
  border: none;
  font-family: "Poppins", sans-serif;

  svg path {
    fill: #2a2a2b !important;
  }
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #cae9ff !important;
  border: 1px solid #cae9ff !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #2a2a2b !important;

  span {
    color: white !important;
  }
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  padding: 4px 16px !important;
}

.ant-steps-item-title {
  color: white !important;
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #cae9ff !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #cae9ff !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #2a2a2b !important;
  font-weight: bold !important;
}

.ant-steps {
  width: 60% !important;
  margin: auto !important;

  @media screen and (max-width: 540px) {
    width: 90% !important;
  }
}

.ant-steps .ant-steps-item-tail::after {
  background-color: #3b3b3c !important;
}

// mobile
.ant-steps.ant-steps-vertical {
  display: flex !important;
  flex-direction: row !important;
}

.ant-steps-item-container {
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  @media screen and (max-width: 540px) {
    display: flex;
  }
}

.ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  @media screen and (max-width: 540px) {
    position: absolute !important;
    background: #3b3b3c !important;
    height: 3px !important;
    left: 56px !important;
    top: 14px !important;
    width: 100% !important;
    padding: 0 !important;
    z-index: -1;
  }
}

.ant-steps-finish-icon {
  svg {
    color: #2a2a2b !important;
  }
}

.ant-select-show-search {
  width: 100% !important;

  // margin-top: 1rem !important;
  @media screen and (max-width: 540px) {
    width: 100% !important;
    margin: auto !important;
  }
}

.ant-select .ant-select-arrow .anticon {
  color: white !important;
}

.ant-select {
  display: block;
}

//////////////////////////////////////////
///

.MuiSwitch-thumb {
  color: #3b3b3c !important;
  // color: red !important;
  height: 13px !important;
  width: 13px !important;
  margin-top: 3px !important;
  margin-left: 3px !important;
}

.MuiSwitch-track {
  background-color: $active-buttons-bg-pink !important;
  opacity: 100% !important;
  border-radius: 10px !important;
}

.Mui-checked+.MuiSwitch-track {
  background-color: $active-buttons-bg !important;
  opacity: 100% !important;
}

.MuiSwitch-root {
  padding: 10px !important;
}

// loader
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.css-1ik0yfp-MuiTypography-root {
  margin-top: 20px !important;
}

.error_email {
  color: red;
  padding-left: 10px;
}

.popups__btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loader_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #000;
  opacity: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: $active-buttons-bg;
  padding: 10px 10px;
  width: auto;
  margin: auto;
  border-radius: 10px;
  color: $active-text-color;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  border: none;
}

.not_login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.cursor__pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.w_full {
  width: 100%;
}

.w_half {
  width: 50%;
}

.flex {
  display: flex;
}

.flex_shrink_0 {
  flex-shrink: 0;
}

.flex_direction_col {
  flex-direction: column;
}

.justify_center {
  justify-content: center;
}

.justify_between {
  justify-content: space-between;
}

.justify_around {
  justify-content: space-around;
}

.justify_end {
  justify-content: end;
}

.items_center {
  align-items: center;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .6rem;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .6rem;
}

.flex_center_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gap_1rem {
  gap: 1rem;
}

.col-gap-9px {
  column-gap: 9px;
}

.w_fit {
  width: fit-content;
}

.w_73 {
  width: 73%;
}

.w_half {
  width: 50%;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.relative {
  position: relative;
}

.font_8px {
  font-size: 8px;
}

.font_xxs {
  font-size: 10px;
}

.font_xm {
  font-size: 12px;
}

.font_sm {
  font-size: 14px;
}

.overflow_auto {
  overflow: auto;
}

.min_h_60vh {
  min-height: 60vh;
}

.leading_tight {
  line-height: 1.25;
}

.leading-none {
  line-height: 1;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.mb_-2 {
  margin-bottom: -2px;
}

.mb_-4 {
  margin-bottom: -4px;
}

.mt_0 {
  margin-top: 0px;
}

.mt_-6 {
  margin-top: -6px;
}

.mt_2 {
  margin-top: 2px;
}

.mt_6 {
  margin-top: 6px;
}

.mt_16 {
  margin-top: 16px;
}

.mt_24 {
  margin-top: 24px;
}

.mt_22 {
  margin-top: 22px;
}

.mt_32 {
  margin-top: 32px;
}

.mb_8 {
  margin-bottom: 8px;
}

.mb_4 {
  margin-bottom: 4px;
}

.opacity_50 {
  opacity: 50%;
}

.font_normal {
  font-weight: 400;
}

.font_medium {
  font-weight: 500;
}

.font_semibold {
  font-weight: 600;
}

.text_white {
  color: white;
}

.text_black {
  color: black;
}

.text_neon_green {
  color: $neon-green;
}

.text_cyan {
  color: $active-buttons-bg;
}

.text_cyan_dark {
  color: $active-buttons-bg-dark;
}

.text_pink {
  color: $active-buttons-bg-pink;
}

.bg_pink {
  background-color: $active-buttons-bg-pink
}

.bg_cyan {
  background-color: $active-buttons-bg
}

.bg_dark_green {
  background-color: $dark-green;
}

.bg_light_gray {
  background-color: $light-gray;
}

.text_left {
  text-align: left;
}

.badge {
  padding: 0px 12px;
  border-radius: 5px;
}

.linear_background {
  background: linear-gradient(0deg, $input-bg, $input-bg), linear-gradient(0deg, $dark-gray, $dark-gray);
}

.svg_container {
  div {
    display: flex;
    padding: 0px;
    margin: 0px;
  }
}

.ant-select-dropdown {
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast-container {
  .Toastify__toast {
    font-size: 14px;

    svg {
      path {
        fill: currentColor;
      }
    }

    .info_svg_width_24 {
      svg {
        width: 20px;
        height: 20px;

        path {
          fill: black;
        }

        rect {
          fill: currentColor;
        }
      }
    }

    @media screen and (max-width: 540px) {
      margin: 8px;
    }
  }



  .Toastify__toast-theme--dark.Toastify__toast--info {
    color: $text-white;
  }

  .Toastify__toast-theme--dark.Toastify__toast--success {
    color: $active-buttons-bg-dark;
  }

  .Toastify__toast-theme--dark.Toastify__toast--error {
    color: $active-buttons-bg-pink;
  }

  .Toastify__progress-bar--error {
    background-color: $active-buttons-bg-pink;
  }

  .Toastify__progress-bar--info {
    background-color: $text-white;
  }

  .Toastify__progress-bar--success {
    background-color: $active-buttons-bg-dark;
  }

}




/* Add styles for the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

/* Add styles for the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 4px;
  /* Set the border-radius of the scrollbar thumb */
}

/* Add styles for the scrollbar track when being hovered */
::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0;
  /* Set the background color when hovered */
}

/* Add styles for the scrollbar thumb when being hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Set the background color of the thumb when hovered */
}

/* Add styles for the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: #fff;
  /* Set the background color of the scrollbar corner */
}


/* Hide scrollbar for Chrome, Safari, and Edge */
.scrollbar_invisible::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar_invisible::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide scrollbar for Firefox */
.scrollbar_invisible {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Show scrollbar on hover */
.scrollbar_invisible:hover::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Apply to all number inputs */
input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* Remove arrows in Chrome, Safari, Edge, and Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}