@import '../../general__style/var.scss';

.biggest_predictions_container {
    .tabs_container {
        margin-top: 1rem;
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }

    .challenges_container {
        display: grid;
        margin-top: 1rem;
        margin-left: 16px;
        margin-right: 16px;
        gap: 1rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @media screen and (max-width: 540px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 4px
        }
    }
}